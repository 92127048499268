import { FilterAPI, StatusAPI, StatusImageAPI, StatusPresetAPI } from "@/api/filters.api";

const state = {
    filter_list: [],
    filter_selected_id: 0,
    filter_status_list: [],  // TODO: Redundant status list
    filter_parent_edit: 0,

    status_list: [],        // TODO: Redundant filter status list
    status_selected_id: 0,
    status_modal: {
        show: false,
        mode: "create",
        id_edit: 0
    },

    status_icon_list: [],
    status_icon_selected_id: 0,
    status_icon_modal: {
        show: false,
        mode: "create",
        id_edit: 0
    },

    status_preset_list: [],
    status_preset_selected_id: 0,
    status_preset_modal: {
        show: false,
        mode: "create",
        id_edit: 0
    },
}

const getters = {
    filterList: state => state.filter_list,
    filterSelectedId: state => state.filter_selected_id,

    filterStatusList: state => state.filter_status_list,

    filterParentEdit: state => state.filter_parent_edit,

    statusList: state => state.status_list,
    statusSelectedId: state => state.status_selected_id,
    statusModal: state => state.status_modal,

    statusIconList: state => state.status_icon_list,
    statusIconSelectedId: state => state.status_icon_selected_id,
    statusIconModal: state => state.status_icon_modal,

    statusPresetList: state => state.status_preset_list,
    statusPresetSelectedId: state => state.status_preset_selected_id,
    statusPresetModal: state => state.status_preset_modal,
};

const actions = {
    async fetchFilterList(context) {
        const response = await FilterAPI.filterSettingsGetAll({deleted: false});

        if (response.status !== 200) {
            console.log("fetchFilterList: Something went wrong", response);
            return;
        }

        context.commit("FILTER_LIST_SET", response.data);
    },

    async filterCreate(context, data) {
        const response = await FilterAPI.filterCreate(data);

        if (response.status !== 200) {
            console.log("filterCreate something went wrong", response);
            return;
        }

        await FilterAPI.filterModifierUpdate(response.data.id, {"ids_modifier": data.ids_modifier});

        await context.dispatch('fetchFilterList');
    },

    async filterUpdate(context, {id, data}) {
        const response = await FilterAPI.filterUpdate(id, data);

        if (response.status !== 200) {
            console.log("filterUpdate something went wrong", response);
            return;
        }
        
        await FilterAPI.filterModifierUpdate(id, {"ids_modifier": data.ids_modifier});

        await context.dispatch('fetchFilterList');

    },

    async filterDelete(context, id_filter) {
        const response = await FilterAPI.filterDelete(id_filter);

        if (response.status !== 200) {
            console.log("filterDelete something went wrong", response);
            return;
        }

        await context.dispatch('fetchFilterList');
    },

    async fetchStatusList(context) {
        const response = await StatusAPI.statusGetAll({deleted: false});

        if (response.status !== 200) {
            console.log("fetchStatusList something went wrong", response)
            return;
        }

        context.commit('FILTER_STATUS_LIST', response.data)  // TODO: :Redundant
        context.commit('STATUS_LIST_SET', response.data)     // TODO: :Redundant
    },

    async statusCreate(context, data) {
        const response = await StatusAPI.statusCreate(data);

        if (response.status !== 200) {
            console.log("statusCreate: Something went wrong", response);
            return;
        }

        context.commit('STATUS_LIST_APPEND', response.data);
    },

    async statusUpdate(context, {id, data}) {
        const response = await StatusAPI.statusUpdate(id, data);

        if (response.status !== 200) {
            console.log("statusUpdate: Something went wrong", response);
            return;
        }

        context.commit('STATUS_LIST_UPDATE', {id: id, data: response.data});
    },

    statusModalOpen(context, {mode, id_edit = 0}) {
        context.commit("STATUS_MODAL", {
            show: true,
            mode: mode,
            id_edit: id_edit
        })
    },

    statusModalClose(context) {
        context.commit("STATUS_MODAL", {
            show: false,
            mode: "create",
            id_edit: 0
        })
    },

    async fetchStatusIconAll(context) {
        const response = await StatusImageAPI.imageGetAll({deleted: false});

        if (response.status !== 200) {
            console.log("SettingsFilter - fetchStatusImageAll: Something went wrong ", response.status);
            return;
        }

        context.commit('STATUS_ICON_LIST_SET', response.data);
    },

    async statusIconCreate(context, data) {
        const response = await StatusImageAPI.imageCreate(data);
        if (response.status !== 200) {
            console.log("statusIconCreate: Something went wrong", response);
            return;
        }

        context.commit('STATUS_ICON_LIST_APPEND', response.data);
    },

    async statusIconUpdate(context, {id, data}) {
        const response = await StatusImageAPI.imageUpdate(id, data);

        if (response.status !== 200) {
            console.log("statusIconUpdate: Something went wrong", response);
            return;
        }

        context.commit('STATUS_ICON_LIST_UPDATE', {id: id, data: response.data});
    },

    statusIconModalOpen(context, {mode, id_edit = 0}) {
        context.commit("STATUS_ICON_MODAL", {
            show: true,
            mode: mode,
            id_edit: id_edit
        })
    },

    statusIconModalClose(context) {
        context.commit("STATUS_ICON_MODAL", {
            show: false,
            mode: "create",
            id_edit: 0
        })
    },

    async fetchStatusPresetAll(context) {
        const response = await StatusPresetAPI.presetGetAll({deleted: false});

        if (response.status !== 200) {
            console.log("SettingsFilter - fetchStatusPresets: Something went wrong ", response.status);
            return;
        }

        context.commit('STATUS_PRESET_LIST_SET', response.data);
    },

    async statusPresetCreate(context, data) {
        const response = await StatusPresetAPI.presetCreate(data);

        if (response.status !== 200) {
            console.log("newStatusSave: Something went wrong", response);
            return;
        }

        context.commit('STATUS_PRESET_LIST_APPEND', response.data);
    },

    async statusPresetUpdate(context, {id, data}) {
        const response = await StatusPresetAPI.presetUpdate(id, data);

        if (response.status !== 200) {
            console.log("statusIconUpdate: Something went wrong", response);
            return;
        }

        context.commit('STATUS_PRESET_LIST_UPDATE', {id: id, data: response.data});
    },

    statusPresetModalOpen(context, {mode, id_edit = 0}) {
        context.commit("STATUS_PRESET_MODAL", {
            show: true,
            mode: mode,
            id_edit: id_edit
        })
    },

    statusPresetModalClose(context) {
        context.commit("STATUS_PRESET_MODAL", {
            show: false,
            mode: "create",
            id_edit: 0
        })
    },

    clearStatusSettings(context) {
        context.commit('CLEAR_STATUS_SETTINGS');
    }
};

const mutations = {
    FILTER_LIST_SET(state, payload) {
        state.filter_list = [...payload];
    },

    FILTER_SELECTED_SET(state, value) {
        state.filter_selected_id = value;
    },

    // FILTER_UPDATE(state, {id_item, data}) {
    //     function find_filter_recursive(node_list) {
    //         for (let node of node_list) {
    //             if (node.id === id_item) {
    //                 return node;
    //             }
    //
    //             if (node.has_child) {
    //                 const result = find_filter_recursive(node.children)
    //
    //                 if (result) {
    //                     return result;
    //                 }
    //             }
    //         }
    //     }
    //
    //     const item = find_filter_recursive(state.filter_list);
    //     if (!item) return;
    //
    //     Object.entries(data).forEach(([key, value]) => {
    //         if (key === 'id' || key === 'children') return;
    //
    //         if (item[key] !== value) {
    //             item[key] = value;
    //         }
    //     });
    // },

    FILTER_STATUS_LIST(state, payload) {
        state.filter_status_list = [...payload];
    },

    FILTER_PARENT_EDIT_SET(state, value) {
        state.filter_parent_edit = value;
    },

    // Status list
    STATUS_LIST_SET(state, payload) {
        state.status_list = [...payload]
    },

    STATUS_LIST_APPEND(state, data) {
        state.status_list.push(data);
    },

    STATUS_LIST_UPDATE(state, {id, data}) {
        const item = state.status_list.find(t => t.id === id);

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'id') return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },

    STATUS_SELECTED(state, value) {
        state.status_selected_id = value;
    },

    STATUS_MODAL(state, {show, mode, id_edit}) {
        state.status_modal = {
            show: show,
            mode: mode,
            id_edit: id_edit
        }
    },

    // Status icon
    STATUS_ICON_LIST_SET(state, payload) {
        state.status_icon_list = [...payload]
    },

    STATUS_ICON_SELECTED(state, value) {
        state.status_icon_selected_id = value;
    },

    STATUS_ICON_MODAL(state, {show, mode, id_edit}) {
        state.status_icon_modal = {
            show: show,
            mode: mode,
            id_edit: id_edit
        }
    },

    STATUS_ICON_LIST_APPEND(state, data) {
        state.status_icon_list.push(data);
    },

    STATUS_ICON_LIST_UPDATE(state, {id, data}) {
        const item = state.status_icon_list.find(t => t.id === id);

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'id') return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },


    // Presets
    STATUS_PRESET_LIST_SET(state, payload) {
        state.status_preset_list = [...payload]
    },

    STATUS_PRESET_LIST_APPEND(state, data) {
        state.status_preset_list.push(data);
    },

    STATUS_PRESET_LIST_UPDATE(state, {id, data}) {
        const item = state.status_preset_list.find(t => t.id === id);

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'id') return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },

    STATUS_PRESET_SELECTED(state, value) {
        state.status_preset_selected_id = value;
    },

    STATUS_PRESET_MODAL(state, {show, mode, id_edit}) {
        state.status_preset_modal = {
            show: show,
            mode: mode,
            id_edit: id_edit
        }
    },

    CLEAR_STATUS_SETTINGS(state) {
        state.status_list = [];
        state.status_selected_id = 0;
        state.status_modal = {show: false, mode: "create", id_edit: 0};

        state.status_icon_list = [];
        state.status_icon_selected_id = 0;
        state.status_icon_modal = {show: false, mode: "create", id_edit: 0};

        state.status_preset_list = [];
        state.status_preset_selected_id = 0;
        state.status_preset_modal = {show: false, mode: "create", id_edit: 0};
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
