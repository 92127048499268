import API, {APIReturn} from '@/api';

const resource = "/filters";

interface IParamsDeleted {
    deleted: boolean
}

interface IParamsPlugin {
    id_plugin: number
}

interface IFilter {
    id: number,
    system_name: string,
    name: string,

    id_camera: number[],
    id_camera_zone: number[],
    id_event_type: number[],
    id_object_type: number[],
    ids_status: number[]

    use_deleted: boolean,

    plugin: number,
    sort_order: number

    use_view: boolean,
    view: string,
    has_child: boolean,
    children: IFilter[],
}

interface IFilterUpdate {
    system_name: string,
    name: string,

    id_camera: number[],
    id_camera_zone: number[],
    id_event_type: number[],
    id_object_type: number[],

    ids_status: number[]
    use_deleted: boolean,

    plugin: number,
    sort_order: number

    id_parent: number
}

interface IModifier {
    id: number,
    system_name: string,
    name: string,
    source: string,
    description: string,
    id_plugin: number
}

interface IModifiers {
    modifiers: IModifier[]
}

export const FilterAPI = {
    filtersGetAll: async (options: IParamsDeleted): APIReturn<Array<IFilter>> => {
        // [GET] /v1/filters/
        return await API.get<Array<IFilter>>(`${resource}/`, { params: options });
    },

    filterSettingsGetAll: async (options: IParamsDeleted): APIReturn<Array<IFilter>> => {
        // [GET] /v1/filters/all/
        return await API.get<Array<IFilter>>(`${resource}/all/`, { params: options });
    },

    filterModifierGet: async (id_filter: number): APIReturn<IModifiers> => {
        // [GET] /v1/filters/{id_filter}/modifier/
        return await API.get<IModifiers>(`${resource}/${id_filter}/modifier/`);
    },

    filterModifierUpdate: async (id_filter: number, ids_modifier: number []): APIReturn<IModifiers> => {
        // [PUT] /v1/filters/{id_filter}/modifier/
        return await API.put<IModifiers>(`${resource}/${id_filter}/modifier/`, ids_modifier);
    },

    filterCreate: async (data: IFilterUpdate): APIReturn<IFilter> => {
        // [POST] /v1/filters/
        return await API.post<IFilter>(`${resource}/`, data);
    },

    filterUpdate: async (id_filter: number, data: IFilterUpdate): APIReturn<IFilter> => {
        // [PUT] /v1/filters/:id/
        return await API.put<IFilter>(`${resource}/${id_filter}/`, data);
    },

    filterDelete: async (id_filter: number): APIReturn<string> => {
        // [DELETE] /v1/filters/:id/
        return await API.delete<string>(`${resource}/${id_filter}/`);
    }
}

export const RefsFilterModifier = {
    refsFilterModifierGetAll: async (options: IParamsPlugin): APIReturn<IModifiers> => {
        // [GET] /v1/filters/refs_filtermodifier/
        console.log(options);
        return await API.get<IModifiers>(`${resource}/refs_filter_modifier/`, { params: options });
    }
}

interface IStatus {
    id: number,
    system_name: string,
    name: string,
    description: string,
    id_status_image: number,
    deleted: boolean,
    icon: IStatusIcon
}

interface IStatusUpdate {
    system_name: string,
    name: string,
    description: string,
    id_status_image: number
}

export const StatusAPI = {
    statusGetAll: async (options: IParamsDeleted): APIReturn<Array<IStatus>> => {
        // [GET] /v1/filters/statuses/
        return await API.get(`${resource}/statuses/`, { params: options });
    },

    statusCreate: async (data: IStatusUpdate): APIReturn<IStatus> => {
        // [POST] /v1/filters/statuses/
        return await API.post<IStatus>(`${resource}/statuses/`, data);
    },

    statusUpdate: async (id: number, data: IStatusUpdate): APIReturn<IStatus> => {
        // [PUT] /v1/filters/statuses/:id/
        return await API.put<IStatus>(`${resource}/statuses/${ id }/`, data);
    },
}

interface IStatusIcon {
    id: number,
    system_name: string,
    css_class: string,
    id_color: number,
    size: number,
    deleted: boolean
}

interface IStatusIconCreate {
    system_name: string
    css_class: string,
    id_color: number,
    size: number,
}

interface IStatusIconUpdate {
    css_class: string,
    id_color: number,
    size: number
}

export const StatusImageAPI = {
    imageGetAll: async (options: IParamsDeleted): APIReturn<Array<IStatusIcon>> => {
        // [GET] /v1/filters/statuses/images/
        return await API.get<Array<IStatusIcon>>(`${resource}/statuses/images/`, { params: options });
    },

    imageCreate: async (data: IStatusIconCreate): APIReturn<IStatusIcon> => {
        // [POST] /v1/filters/statuses/images/
        return await API.post<IStatusIcon>(`${resource}/statuses/images/`, data);
    },

    imageUpdate: async (id: number, data: IStatusIconUpdate): APIReturn<IStatusIcon> => {
        // [PUT] /v1/filters/statuses/images/:id/
        return await API.put<IStatusIcon>(`${resource}/statuses/images/${id}/`, data);
    },
}

interface IPreset {
    id: number,
    system_name: string,
    name: string,
    description: string,
    block: boolean,
    deleted: boolean,
    ids_status: number[]
}

interface IPresetUpdate {
    system_name: string,
    name: string,
    description: string,
    ids_status: number[]
}

export const StatusPresetAPI = {
    presetGetAll: async (options: IParamsDeleted): APIReturn<Array<IPreset>> => {
        // [GET] /v1/filters/statuses/presets/
        return await API.get<Array<IPreset>>(`${resource}/statuses/presets/`, { params: options });
    },

    presetCreate: async (data: IPresetUpdate): APIReturn<IPreset> => {
        // [POST] /v1/filters/statuses/presets/
        return await API.post<IPreset>(`${resource}/statuses/presets/`, data);
    },

    presetUpdate: async (id: number, data: IPresetUpdate): APIReturn<IPreset> => {
        // [PUT] /v1/filters/statuses/presets/:id/
        return await API.put<IPreset>(`${resource}/statuses/presets/${id}/`, data);
    },
}
