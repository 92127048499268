import API, { APIReturn } from '@/api';

const RESOURCE = "/video";

interface IVideoParams {
    begin_time: number,
    end_time: number,
    id_camera: number
}

interface IVideo {
    url: string,
    file_path: string,
    start_time: number,
    end_time: number,
    start_unix_time: number,
    end_unix_time: number
}

interface ICorrectedTimeParams extends IVideoParams {
    path_to_file: string
}

interface ICorrectedTime {
    time_video: number,
    time_real: number
}

interface IArchiveParams {
    id_camera: number,
    date: string
}

interface IArchive {
    begin_time: number,
    end_time: number
}

export const VideoAPI = {
    videoGet: async (options: IVideoParams): APIReturn<IVideo> => {
        // [GET] /v1/video/
        return await API.get<IVideo>(`${RESOURCE}/`, { params: options });
    },

    correctedTimeGet: async (options: ICorrectedTimeParams): APIReturn<Array<ICorrectedTime>> => {
        // [GET] /v1/video/corrected_time/
        return await API.get<Array<ICorrectedTime>>(`${RESOURCE}/corrected_time/`, { params: options });
    },

    archiveGet: async (options: IArchiveParams): APIReturn<Array<IArchive>> => {
        // [GET] /v1/video/archive/day/
        return await API.get<Array<IArchive>>(`${RESOURCE}/archive/day/`, { params: options });
    },
}

interface IObjectParams {
    begin_time: number,
    end_time: number
    id_camera: number,
    id_track: number[],
    id_object_type: number[],
    use_track: boolean,
    id_event?: number
}

interface IMaskParams {
    begin_time: number,
    end_time: number,
    id_camera: number,
    id_track: number [],
    id_object_type: number [],
}

interface ILineParams {
    begin_time: number,
    end_time: number,
    id_camera: number,
    id_track: number [],
    id_object_type: number []
}

interface IObjectEntity {
    id_track: number,
    is_track: boolean,
    type: number,
    x: number,
    y: number,
    w: number,
    h: number,
}

interface IObject {
    object_time: number,
    entity_list: IObjectEntity[]
}

interface IPoints {
    x: number,
    y: number,
}

interface IMaskEntity {
    id_track: number,
    is_track: boolean,
    type: number,
    points: IPoints [],
}

interface IMask {
    object_time: number,
    masks: IMaskEntity [],
}

interface ILineEntity {
    id_track: number,
    is_track: boolean,
    type: number,
    first_point: IPoints,
    end_point: IPoints,
    distance: number,
}

interface ILine {
    object_time: number,
    lines: ILineEntity [],
}

interface IObjectTypeParams {
    hide: false
}

interface IObjectType {
    id: number,
    system_name: string,
    name: string,
    id_color: number
}

export const ObjectAPI = {
    objectsGetAll: async (options: IObjectParams): APIReturn<Array<IObject>> => {
        // [GET] /v1/video/objects/
        return await API.get<Array<IObject>>(`${RESOURCE}/objects/`, { params: options });
    },

    masksGetAll: async (options: IMaskParams): APIReturn<Array<IMask>> => {
        return await API.get<Array<IMask>>(`${RESOURCE}/masks/`, { params: options });
    },

    lineGetAll: async (options: ILineParams): APIReturn<Array<ILine>> => {
        return await API.get<Array<ILine>>(`${RESOURCE}/lines/`, { params: options });
    },

    objectTypeGetAll: async (options: IObjectTypeParams): APIReturn<Array<IObjectType>> => {
        // [GET] /v1/video/objects/types/
        return await API.get<Array<IObjectType>>(`${RESOURCE}/objects/types/`, { params: options });
    },
}
