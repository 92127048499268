import Vue from 'vue';

import { formatDateTime, formatDate, formatTime, fastTimeFormat } from '@/utils/time.js';

Vue.filter('formatDateTime', function (value) {
    if (value) {
        return formatDateTime(value)
    }
});


Vue.filter('formatDate', function (value) {
    if (value) {
        return formatDate(value);
    }
});

Vue.filter('formatTime', function (value) {
    if (value) {
        return formatTime(value);
    }
});

Vue.filter('formatFastTime', function(value) {

    if (typeof value !== 'number') {
        console.error(`formatFastTime - Unknown type of value: ${value}`);
        return fastTimeFormat(0);
    }

    if (value < 0) {
        console.warn(`formatFastTime - Value is less than 0: ${value}`);
        return fastTimeFormat(0);
    }

    return fastTimeFormat(value);
});
