import axios from 'axios';

const ServerURL = axios.create({
    baseURL: (process.env.NODE_ENV === 'development') ? 'http://localhost:8000' : ''
});

class ErrorService {
    _instance = null;

    constructor(vue) {
        if (this._instance) {
            return this._instance;            
        }
        console.log("Create error service");

        this.vue = vue;

        this.onWindowError();

        this._instance = this;
        return this._instance;
    }

    sendToServer(payload) {
        ServerURL.post('/error', payload).then(response => {
            console.log("Error service response");
            console.log(response);
        }).catch(error => {
            console.log("Error service error");
            console.log(error);
        })
    }    

    onVueError(err, vm, info) {
        console.log("Catched vue error. Trying to send it to backend...");

        // TODO: Remember to fix this when we have user_id in userInfo
        // let username = "";
        // if (vm.$store.getters.userInfo) {
        //     username = vm.$store.getters.userInfo.username;
        // }

        let payload = {
            type: "vue",
            filename: vm.$options.name || "",
            message: `${err.message}\n${info}`,
            stackTrace: err.stack,
            user: 0
        }
        this.sendToServer(payload);
    }

    onWindowError() {
        const scope = this;

        window.onerror = function (message, source, lineNo, columnNo, error) {
            console.log("Catched window error. Trying to send it to backend...");

            let payload = {
                type: "window",
                filename: source,
                message: message,
                stackTrace: error.stack,
                user: 0
            }
            scope.sendToServer(payload);   
        };
    }
}

export default ErrorService;