import API, {APIReturn} from "@/api";

const resource = "/ozon";

interface IDataParams {
    begin_time: string,
    end_time: string,

    external_id: number,
    gate_name: string,
    warehouse: string,
    supplier_name: string,
    status_id: number,

    show_all: number,

    limit: number,
    offset: number,
    id_filter: number,
    load_info: boolean
}

interface IData {
    id: number,
    id_external: number,
    id_status: number,
    status_name: string,
    id_supplier: number,
    supplier_name: string,
    id_gate: number,
    gate_name: string,
    id_warehouse: number,
    warehouse_name: string,
    begin_time: number,
    end_time: number,
    planned_arrival: number,
    planned_palets: number
}

interface IDataInfo {
    closed: boolean,
    count: 0,
    data: IData[]
}

interface IDataPeriodParams {
    begin_time: string,
    id_filter: number,

    external_id: number,
    gate_name: string,
    warehouse: string,
    supplier_name: string,
    status_id: number,
    show_all: number,
}

interface IDataPeriod {
    time_period: string[]
}

interface IEventParams {
    begin_time: string,
    end_time: string,
    id_filter: number,
    id_parent: number,
    limit: number,
    offset: number,
    load_info: boolean
}

// TODO: inheritance from event.api IEvent interface
interface IEvent {
    id: number,
    begin_time: number,
    end_time: number,
    id_camera: number,
    id_camera_zone: number,
    id_event_type: number,
    comment: string,
    id_state: number,
    deleted: boolean,
    closed: boolean,
    has_child: boolean,
    show_id_camera_zone: number,
    // TODO: attrs?
    supply_id: number
}

// TODO: inheritance from event.api IEvents interface
interface IEvents {
    count: number,
    // TODO: boolean?
    data: IEvent[]
}

interface IEventSetState {
    id_state: number,
    begin_time: string,
    end_time: string,
    id_filter: number
}

interface IDataStatus {
    id: number,
    name: string
}

export const OzonAPI = {
    getAll: async (options: IDataParams): APIReturn<IDataInfo> => {
        // [GET] /v1/ozon/
        return await API.get<IDataInfo>(`${resource}/`, {params: options});
    },

    getPeriod: async (options: IDataPeriodParams): APIReturn<IDataPeriod> => {
        // [GET] /v1/ozon/period/
        return await API.get<IDataPeriod>(`${resource}/period/`, { params: options })
    },

    getEventList: async (id: number, options: IEventParams): APIReturn<IEvents> => {
        // [GET] /v1/ozon/:id/events/
        return await API.get<IEvents>(`${resource}/${id}/events/`, {params: options})
    },

    eventSetGlobalState: async (id: number, options: IEventSetState): APIReturn<string> => {
        // [GET] /v1/ozon/:id/events/set_state/
        return await API.put<string>(`${resource}/${id}/events/set_state/`, null, { params: options })
    },

    getStatusList: async(): APIReturn<Array<IDataStatus>> => {
        // [GET] /v1/ozon/statuses/
        return await API.get<Array<IDataStatus>>(`${resource}/statuses/`);
    }
}
