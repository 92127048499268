import API, { APIReturn } from '@/api';
import { getSidLocal } from "@/storage/token.storage";

const RESOURCE = "/events";

export interface IEventParams {
    begin_time: string,
    end_time: string,

    id_camera: number[],
    id_camera_zone: number[],
    id_event_type: number[],

    id_filter: number,

    offset: number,
    limit: number,
    load_info: boolean
}

export interface IEvent {
    id: number,
    begin_time: number,
    end_time: number,
    id_camera: number,
    id_camera_zone: number,
    id_event_type: number,
    comment: string,
    id_state: number,
    deleted: boolean,
    closed: boolean,
    has_child: boolean,
    show_id_camera_zone: number,
    attrs: object  // TODO: make it proper
}

interface IEvents {
    count: number,
    closed: boolean,
    data: IEvent[]
}

interface IEventCreate {
    begin_time: number,
    end_time: number,
    id_camera: number,
    id_camera_zone: number,
    id_event_type: number,
    comment: string,
    id_parent: number
}

export interface IEventUpdate extends IEventCreate {
    id_state: number
}

interface ICloseDay {
    id_filter: string,
    begin_time: string,
    end_time: string
}

export interface IGlobalState {
    id_state: number,
    begin_time: string,
    end_time: string,
    id_camera: number[],
    id_camera_zone: number[]
    id_event_type: number[],
    id_filter: number
}

interface IEventChildrenParam {
    begin_time: string,
    end_time: string,

    id_camera: number[],
    id_camera_zone: number[],
    id_event_type: number[],

    id_filter: number,
}

export interface IPeriodParams {
    begin_time: string,
    id_camera: number[],
    id_camera_zone: number[],
    id_event_type: number[],
    id_filter: number
}

export interface IPeriod {
    time_period: string[]
}

export interface ITrimEventVideo {
    id: number,
    sid: string
}

export const EventAPI = {
    eventGetAll: async (options: IEventParams): APIReturn<IEvents> => {
        // [GET] /v1/events/
        return await API.get<IEvents>(`${RESOURCE}/`, { params: options });
    },

    eventCreate: async (data: IEventCreate): APIReturn<IEvent> => {
        // [POST] /v1/events/
        return await API.post<IEvent>(`${RESOURCE}/`, data);
    },

    eventUpdate: async (id: number, data: IEventUpdate): APIReturn<IEvent> => {
        // [PUT] /v1/events/:id/
        return await API.put<IEvent>(`${RESOURCE}/${id}/`, data);
    },

    eventDelete: async (id: number): APIReturn<string> => {
        // [DELETE] /v1/events/:id/
        return await API.delete<string>(`${RESOURCE}/${id}/`);
    },

    closeDay: async (options: ICloseDay): APIReturn<string> => {
        // [GET] /v1/events/close/
        return await API.get<string>(`${RESOURCE}/close/`, { params: options });
    },

    setGlobalState: async (options: IGlobalState): APIReturn<string> => {
        // [PUT] /v1/events/set_state/
        return await API.put(`${RESOURCE}/set_state/`, null, {params: options});
    },

    eventGetChildren: async(id: number, options: IEventChildrenParam): APIReturn<Array<IEvent>> => {
        // [GET] /v1/events/:id/child/
        return await API.get<Array<IEvent>>(`${RESOURCE}/${id}/child/`, { params: options });
    },

    periodGet: async (options: IPeriodParams): APIReturn<IPeriod> => {
        // [GET] /v1/events/period/
        return await API.get<IPeriod>(`${RESOURCE}/period/`, { params: options });
    },

    trimEventVideo: async (data: ITrimEventVideo): APIReturn<any> => {
        return await API.post<any>(`${RESOURCE}/trim/`, data);
    }
};

interface IEventTypeParams {
    deleted: boolean
}

interface IEventType {
    id: number,
    system_name: string,
    name: string,
    deleted: boolean,
    sort_order: number,
    last_update: number,
    color: number
}

export const EventTypeAPI = {
    eventTypeGetAll: async (options: IEventTypeParams): APIReturn<Array<IEventType>> => {
        // [GET} /v1/events/types/
        return await API.get<Array<IEventType>>(`${RESOURCE}/types/`, { params: options});
    }
};
