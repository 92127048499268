<template>
    <div class="flex flex-col w-full text-sm no-selection">
        <!-- Название группы -->
        <div
            class="flex mr-2 flex-row group-parent w-full bg-item px-2 py-1 cursor-pointer justify-between"
            @click="isOpened = !isOpened"
        >
            <div class="flex flex-row items-center">

                <div class="mr-1" @click.stop="selectAll">
                    <v-icon
                        color="#ffffff"
                        size="12"
                    >
                        {{
                            selectedAll ? 'fas fa-square' : selectedSomething ? 'far fa-minus-square' : 'far fa-square'
                        }}

                    </v-icon>
                </div>
                <div class="font-bold">{{ name }}</div>
                <div class="ml-1 text-xs" v-show="activeCount > 0">
                    ( <span class="font-bold text-success">{{ activeCount }}</span> )
                </div>
            </div>

            <div>
                <v-icon color="#ADAFB3" size="16">
                    {{
                        isOpened
                            ? "fas fa-chevron-up"
                            : "fas fa-chevron-down"
                    }}
                </v-icon>
            </div>
        </div>

        <div class="flex flex-col group-item-list" v-show="isOpened">
            <div v-for="item in item_list" :key="item.id">
                <v-tooltip
                    bottom
                    color="#242526"
                    :open-delay="200"
                    :nudge-bottom="-10"
                    :disabled="shouldShowTooltip(item)"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            class="flex flex-row items-center cursor-pointer group-item px-2 py-1"
                            @click="toggleItem(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon
                                :color="
                                    item.active ? '#34d859' : '#ffffff'
                                "
                                class="self-start py-1"
                                size="14"
                            >
                                {{
                                    item.active
                                        ? "fas fa-check-square"
                                        : "far fa-square"
                                }}
                            </v-icon>
                            <div class="ml-1 text-sm group-item-name">
                                {{ item.name }}
                            </div>
                        </div>
                    </template>
                    <span class="text-white">{{ item.name }}</span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckboxGroup",

    props: {
        name: {
            type: String,
            default: ""
        },

        item_list: {
            type: Array,
            default: function() {
                return []
            }
        }
    },

    data: () => ({
        isOpened: false
    }),

    computed: {
        activeCount() {
            if (!this.item_list.length) return 0;

            return this.item_list.filter(t => t.active).length;
        },

        selectedAll() {
            return this.activeCount === this.item_list.length;
        },

        selectedSomething() {
            return this.activeCount > 0 || this.activeCount === this.item_list.length;
        }
    },

    methods: {
        toggleItem(item) {
            item.active = !item.active;
            this.$emit('toggleItem');
        },

        shouldShowTooltip(item) {
            return item.name.length < 30;
        },

        selectAll() {
            if (this.selectedAll) {
                // Если выбраны все, нужно отменить все
                this.item_list.forEach(value => value.active = false);
                this.$emit('toggleItem');
                return;
            }

            this.item_list.forEach(value => value.active = true);
            this.$emit('toggleItem');
        }
    }
};
</script>

<style scoped>
.group-parent {
    border: 1px solid #6b7280;
}

.group-parent:hover {
    background-color: var(--white-alpha-02);
}

.group-item {
    background-color: var(--item-background);
}

.group-item:hover {
    background-color: var(--white-alpha-02);
}

.group-item-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.group-item-list {
    border-color: #6b7280;
    border-style: solid;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
}
</style>
