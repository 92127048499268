import API, { APIReturn } from '@/api'

const RESOURCE_USER = "/users";
const RESOURCE_ACCESS = "/users/access";


interface IUser {
    id: number,
    login: string,
    name: string,
    email: string,
    deleted: boolean,
    blocked: boolean,
    last_seen: number,
    is_super_user: boolean,
    list_access?: Array<string>
}

interface IUserCreate {
    login: string,
    password: string,
    email: string,
    name: string
}

interface IRule {
    id: number,
    id_rule_group: number,
    name: string,
    system_name: string
}

interface IRuleGroup {
    id: number,
    name: string,
    system_name: string
    description: string
}

interface IRole {
    id: number,
    name: string,
    system_name: string
    description: string
    blocked: boolean,
    deleted: boolean
}

interface IRoleCreate {
    name: string,
    system_name: string
    description: string
}

interface IRoleRule {
    id: number,
    active: boolean
}

interface IRoleRuleUpdate {
    id_rule: number,
    active: boolean
}

interface IUserRoleUpdate {
    id_role: number
}

interface IUserRuleUpdate {
    id_rule: number,
    active: boolean
}

interface IUserRule {
    id: number,
    id_rule_group: number,
    name: string,
    active: boolean,
    origin: string
}

export default {
    userGetAll: async (): APIReturn<Array<IUser>> => {
        /*
            Получение списка всех пользователей
        */
        return await API.get<IUser[]>(`${RESOURCE_USER}/`);
    },

    userCreate: async (data: IUserCreate): APIReturn<IUser> => {
        /*
            Создание пользователя
        */
        return await API.post<IUser>(`${RESOURCE_USER}`, data);
    },

    userDelete: async (id_user: number): APIReturn<IUser> => {
        return await API.delete<IUser>(`${RESOURCE_USER}/${id_user}/`);
    },

    userBlock: async (id_user: number): APIReturn<IUser> => {
        return await API.put<IUser>(`${RESOURCE_USER}/${id_user}/block/`);
    },

    userGet: async (id_user: number): APIReturn<IUser> => {
        /*
            Get user by id
        */
        return await API.get<IUser>(`${RESOURCE_USER}/${id_user}`);
    },

    userUpdate: async(id_user: number, data: IUser): APIReturn<IUser> => {
        /*
            Update user by id
        */
        return await API.put<IUser>(`${RESOURCE_USER}/${id_user}`, data);
    },

    userGetMe: async (): APIReturn<IUser> => {
        /*
            Get an information of the current user
        */
        return await API.get<IUser>(`${RESOURCE_USER}/me/`);
    },

    rulesGetAll: async(): APIReturn<IRule[]> => {
        /*
            Get list of rules
        */
        return await API.get<IRule[]>(`${RESOURCE_ACCESS}/rules/`);
    },

    ruleGroupsGetAll: async(): APIReturn<IRuleGroup[]> => {
        /*
            Get list of rule groups
        */
        return await API.get<IRuleGroup[]>(`${RESOURCE_ACCESS}/rules/groups/`);
    },

    rolesGetAll: async(): APIReturn<IRole[]> => {
        /*
            Get list of roles
        */
        return await API.get<IRole[]>(`${RESOURCE_ACCESS}/roles/`);
    },

    roleCreate: async(data: IRoleCreate): APIReturn<IRole> => {
        /*
            Create a new role
        */
        return await API.post<IRole>(`${RESOURCE_ACCESS}/roles/`, data);
    },

    roleUpdate: async(id_role: number, data: IRoleCreate): APIReturn<IRole> => {
        // Role update

        return await API.put<IRole>(`${RESOURCE_ACCESS}/roles/${id_role}/`, data);
    },

    roleDelete: async(id_role: number): APIReturn<string> => {
        // Role delete

        return await API.delete<string>(`${RESOURCE_ACCESS}/roles/${id_role}/`);
    },

    roleRuleGetList: async(id_role: number): APIReturn<IRoleRule[]> => {
        /*
            Get rule list for the role
        */
        return await API.get<IRoleRule[]>(`${RESOURCE_ACCESS}/roles/${id_role}/rules/`);
    },

    roleRuleUpdate: async(id_role: number, data: IRoleRuleUpdate[]): APIReturn<string> => {
        /*
            Update rule list for the role
        */
        return await API.put<string>(`${RESOURCE_ACCESS}/roles/${id_role}/rules/`, data);
    },

    userRoleGetAll: async(id_user: number): APIReturn<IRole[]> => {
        // User role get all list
        return await API.get<IRole[]>(`${RESOURCE_USER}/${id_user}/access/role/`);
    },

    userRoleUpdate: async(id_user: number, data: IUserRoleUpdate): APIReturn<string> => {
        // User role update
        return await API.put<string>(`${RESOURCE_USER}/${id_user}/access/role/`, data);
    },

    userRoleDelete: async(id_user: number, data: IUserRoleUpdate): APIReturn<string> => {
        // User role delete
        return await API.delete<string>(`${RESOURCE_USER}/${id_user}/access/role/`, { data: data});
    },

    userRuleGetAll: async(id_user: number): APIReturn<IUserRule[]> => {
        return await API.get<IUserRule[]>(`${RESOURCE_USER}/${id_user}/access/rule/`);
    },

    userRuleUpdate: async(id_user: number, data: IUserRuleUpdate[]): APIReturn<string> => {
        return await API.put<string>(`${RESOURCE_USER}/${id_user}/access/rule/`, data);
    },
}
