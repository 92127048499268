import Vue from 'vue';
export const EventBus = new Vue();


// Ниже представлен список всех констант для событий с описанием
// что каждый из них делает

/**
 * Событие запрашивания обновления данных.
 * Приходит в основном из главного модуля MainView когда
 * изменяется набор фильтров, параметры или дата в системе.
 * Целью является запрос новых данных из плагинов. Обычно плагины
 * релизуют под этим событие забор своих новых данных для отображения
 *
 * Example:
 *   - EventBus.$on(NEED_TO_FETCH_DATA, this.fetchDataList);
 *
 * Обязательно при удалении компонента вызывать удаление бинда к этому событию,
 * иначе будет плохо.
 *
 * Example:
 *  - beforeDestroy() { EventBus.$off(NEED_TO_FETCH_DATA, this.fetchDataList); }
 *
 *  @type {string}
 */
export const NEED_TO_FETCH_DATA = 'need-to-fetch-data';


/**
 * Событие запрашивания ленивой подгрузки событий.
 * Вызывается из компонента EventList.
 * Так как он является общий для всех плагинов, то требуется иметь
 * какое-то общение для подгрузки новых данных.
 * Целью является ленивая подгрузка новых данных по событиям без дополнительных
 * аттрибутов.
 *
 * Example:
 *   - EventBus.$on(EVENT_LIST_LAZY_LOAD, this.fetchEventListLazy);
 *
 * Обязательно при удалении компонента вызывать удаление бинда к этому событию,
 * иначе будет плохо.
 *
 * Example:
 *  - beforeDestroy() { EventBus.$off(EVENT_LIST_LAZY_LOAD, this.fetchEventListLazy); }
 *
 *  @type {string}
 */

export const EVENT_LIST_LAZY_LOAD = 'event-list-lazy-load';


/**
 * Событие запрашивается когда список событий выставляет глобальный статус событий
 * в выпадающей меню сверху.
 *
 * Так как список событий общий для всех, то требуется иметь общение между двумя независимыми компонентами.
 *
 * !!!!!
 * Имеет параметр id_state который передаёт идентификатор статуса
 * !!!!!
 *
 * Example:
 *   - EventBus.$on(EVENT_LIST_SET_STATE, this.eventListSetState);
 *
 * Обязательно при удалении компонента вызывать удаление бинда к этому событию,
 * иначе будет плохо.
 *
 * Example:
 *  - beforeDestroy() { EventBus.$off(EVENT_LIST_SET_STATE, this.eventListSetState); }
 * @type {string}
 */
export const EVENT_LIST_SET_STATE = "event_list_global_state";
