import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'
// import Vuelidate from "vuelidate";

// Vue.use(Vuelidate);

Vue.use(Vuetify)

export default new Vuetify({
  components: {
  },
  lang: {
    locales: { ru },
    current: 'ru'
  },
  icons: {
    iconfont: 'fa'
  }
})
