import { KkoAPI } from "@/api/kko.api";
import { timeFromISO } from "@/utils/time";

const state = {
    // Загружаеются ли чеки
    is_kko_loading: false,

    kko_list: [],

    kko_selected_id: 0,

    kko_count: 0,

    /** Чанки данных */
    kko_chunk_limit: 25,
    kko_chunk_offset: 0,

    kko_search_text: "",

    is_show_all_cheques: false,
    is_search_ignore_time: false,

    info_list: []
}

const getters = {
    isKkoLoading: state => state.is_kko_loading,
    kkoList: state => state.kko_list,

    kkoSelectedId: state => state.kko_selected_id,
    kkoCount: state => state.kko_count,

    kkoChunkLimit: state => state.kko_chunk_limit,
    kkoChunkOffset: state => state.kko_chunk_offset,

    kkoSearchText: state => state.kko_search_text,

    isShowAllCheques: state => state.is_show_all_cheques,
    isSearchIgnoreTime: state => state.is_search_ignore_time,

    infoList: state => state.info_list,
};

const actions = {
    async fetchKkoList(context, {load_info}) {
        if (load_info) {
            context.commit('SET_KKO_LOADING', true);
        }

        let options = await context.dispatch('base/baseOptions', null, {root: true});

        if (context.getters.isSearchIgnoreTime) {
            const new_bt = timeFromISO(new Date('2000-01-01').toISOString().substr(0, 10)).toFormat("yyyy-LL-dd HH:mm");
            const new_et = timeFromISO(new Date().toISOString().substr(0, 10)).toFormat("yyyy-LL-dd HH:mm");

            options.begin_time = new_bt;
            options.end_time = new_et;
        }

        options = {
            ...options,
            offset: context.getters.kkoChunkOffset,
            limit: context.getters.kkoChunkLimit,
            load_info: load_info,

            search: context.getters.kkoSearchText,
            show_all: context.getters.isShowAllCheques
        }

        const response = await KkoAPI.kkoGetAll(options);
        const payload = response.data;

        if (load_info) {
            context.commit('KKO_LIST_SET', payload.data);
            context.commit("KKO_COUNT_SET", payload.count);
            context.commit('base/SET_IS_DAY_CLOSED', payload.closed, {root: true})

            context.commit('SET_KKO_LOADING', false);
        } else {
            context.commit('KKO_LIST_APPEND', payload.data);

        }
    },

    async fetchKkoPeriod(context) {
        let options = await context.dispatch('base/baseOptionsPeriod', null, {root: true})

        options = {
            ...options,
            show_all: context.getters.isShowAllCheques
        }

        const response = await KkoAPI.periodGet(options);

        await context.dispatch(
            "core/setDataTimePeriod",
            response.data.time_period,
            {root: true}
        );
    },

    async fetchEventList(context, {load_info}) {
        const currentItemId = context.getters.kkoSelectedId;

        if (!currentItemId) {
            await context.dispatch('event/clearEventList', null, {root: true});
            return;
        }

        const currentItem = context.getters.kkoList.find(t => t.id === currentItemId);

        if (!currentItem) {
            console.log("Error fetchEventList: Can't find item in KKO list with id", currentItemId);
            await context.dispatch('event/clearEventList', null, {root: true});
            return;
        }

        if (load_info) {
            await context.dispatch('event/setEventLoading', true, {root: true});
            await context.dispatch('event/setEventChunkOffset', 0, {root: true});
        }

        let options = await context.dispatch('base/baseOptions', null, {root: true});

        options = {
            ...options,
            offset: context.rootGetters['event/eventChunkOffset'],
            limit: context.rootGetters['event/eventChunkLimit'],
            load_info: load_info
        }

        const response = await KkoAPI.eventGetAll(currentItem.purchase, options);

        const payload = response.data;

        if (load_info) {
            context.dispatch('event/setEventList', payload.data, {root: true});
            context.dispatch('event/setEventCount', payload.count, {root: true});
            context.dispatch('event/setEventLoading', false, {root: true});
        } else {
            context.dispatch('event/eventListAppend', payload.data, {root: true});
        }
    },

    async fetchInfoList(context) {
        const currentItemId = context.getters.kkoSelectedId;

        if (!currentItemId) {
            return;
        }

        const currentItem = context.getters.kkoList.find(t => t.id === currentItemId);

        if (!currentItem) {
            console.log("Error fetchInfoList: Can't find item in KKO list with id", currentItemId);
            return;
        }

        const response = await KkoAPI.infoGetAll(currentItem.purchase);

        context.commit("INFO_LIST_SET", response.data);
    },

    infoListClear(context) {
        context.commit("INFO_LIST_CLEAR");  // TODO:
    },

    kkoListClear(context) {
        context.commit("KKO_LIST_CLEAR");
    },

    kkoSelectedSet(context, value) {
        context.commit("KKO_SELECTED_SET", value);
    },

    kkoChunkOffsetSet(context, value) {
        context.commit('KKO_CHUNK_OFFSET_SET', value);
    },

    kkoChunkLimitSet(context, value) {
        context.commit('KKO_CHUNK_LIMIT_SET', value);
    },

    kkoSearchTextSet(context, value) {
        context.commit('KKO_SEARCH_TEXT_SET', value);
    },

    isShowAllChequesSet(context, value) {
        context.commit('IS_SHOW_ALL_CHEQUES_SET', value);
    },

    isSearchIgnoreTimeSet(context, value) {
        context.commit('IS_SEARCH_IGNORE_TIME_SET', value);
    },

    kkoCountSet(context, value) {
        context.commit('KKO_COUNT_SET', value);
    },

    async eventSetGlobalState(context, id_state) {
        const currentItemId = context.getters.kkoSelectedId;

        if (!currentItemId) {
            return;
        }

        const currentItem = context.getters.kkoList.find(t => t.id === currentItemId);

        if (!currentItem) {
            console.log("Error eventSetGlobalState: Can't find item in KKO list with id", currentItemId);
            return;
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            id_state: id_state
        }

        const response = await KkoAPI.eventSetGlobalState(currentItem.purchase, options);

        if (response.status === 403) {
            await context.dispatch("core/addNotificationError", {
                    header: "Закрытое событие",
                    message:
                        "Вы не можете обновлять закрытые события.",
                    timeout: 3000,
                }, { root: true });
            return;
        }

        if (response.status !== 200) {
            await context.dispatch("core/addNotificationError", {
                header: "Ошибка обновления события",
                message:
                    "Произошла какая-то ошибка с обновлением события...",
                timeout: 2500,
            }, { root: true });
            return
        }

        // Сбрасываем текущее событие, чтобы не было проблем с родительским
        await context.dispatch("event/clearEventParentId", null, { root: true});
        await context.dispatch('event/setEventSelected', 0, { root: true});
        await context.dispatch('fetchEventList', { load_info: true });
    },

    setKkoLoading(context, value) {
        context.commit('SET_KKO_LOADING', value);
    }
};

const mutations = {
    SET_KKO_LOADING(state, value) {
        state.is_kko_loading = value;
    },

    KKO_LIST_SET(state, payload) {
        state.kko_list = [ ...payload ];

        if (state.kko_list.length > 0) {
            state.kko_selected_id = state.kko_list[0].id;
        } else {
            state.kko_selected_id = 0;
        }
    },

    KKO_LIST_APPEND(state, payload) {
        state.kko_list = [
            ...state.kko_list,
            ...payload
        ]
    },

    KKO_LIST_CLEAR(state) {
        state.kko_list = [];
    },

    INFO_LIST_SET(state, payload) {
        state.info_list = [...payload]
    },

    INFO_LIST_CLEAR(state) {
        state.info_list = [];
    },

    KKO_SELECTED_SET(state, value) {
        state.kko_selected_id = value;
    },

    KKO_COUNT_SET(state, value) {
        state.kko_count = value;
    },

    KKO_CHUNK_OFFSET_SET(state, value) {
        state.kko_chunk_offset = value;
    },

    KKO_CHUNK_LIMIT_SET(state, value) {
        state.kko_chunk_limit = value;
    },

    KKO_SEARCH_TEXT_SET(state, value) {
        state.kko_search_text = value;
    },

    IS_SHOW_ALL_CHEQUES_SET(state, value) {
        state.is_show_all_cheques = value;
    },

    IS_SEARCH_IGNORE_TIME_SET(state, value) {
        state.is_search_ignore_time = value;
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
