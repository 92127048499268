const ID_TOKEN_KEY = 'id_token';

export const getTokenLocal = () => window.localStorage.getItem(ID_TOKEN_KEY);
export const saveTokenLocal = (value) => window.localStorage.setItem(ID_TOKEN_KEY, value);
export const removeTokenLocal = () => window.localStorage.removeItem(ID_TOKEN_KEY);


const SID_SOCKET_ID = 'sid_socket';
export const getSidLocal = () => window.localStorage.getItem(SID_SOCKET_ID);
export const saveSidLocal = (value) => window.localStorage.setItem(SID_SOCKET_ID, value);
export const removeSidLocal = () => window.localStorage.removeItem(SID_SOCKET_ID)


// const LNG_CLIENT = 'lng';

// export const getLanguageLocal = () => window.localStorage.getItem(LNG_CLIENT);
// export const saveLanguageLocal = () => window.localStorage.setItem(LNG_CLIENT, value);
// export const removeLanguageLocal = () => window.localStorage.removeItem(LNG_CLIENT);

// export const getTokenSession = () => window.sessionStorage.getItem(ID_TOKEN_KEY);
// export const saveTokenSession = (token) => window.sessionStorage.setItem(ID_TOKEN_KEY, token);
// export const removeTokenSession = () => window.sessionStorage.removeItem(ID_TOKEN_KEY);


const VIDEO_VOLUME = 'video_volume';

export const getVideoVolumeLocal = () => window.localStorage.getItem(VIDEO_VOLUME);
export const saveVideoVolumeLocal = (value) => window.localStorage.setItem(VIDEO_VOLUME, value);
export const removeVideoVolumeLocal = () => window.localStorage.removeItem(VIDEO_VOLUME);
