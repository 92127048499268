import API, { APIReturn } from '@/api';

const resource = "/scenarios"

interface ISetting {
    id: number,
    function_id: number,
    system_name: string,
    name: string,
    type: string,
    value: any
}

interface ISettingUpdate {
    system_name: string,
    value: string
}

interface IScenario {
    id: number,
    group_id: number,
    function_name: string,
    name: string,
    use_camera_all: boolean,
    use_time: boolean,
    ids_event_type: number[],
    ids_camera: number[],
    settings: ISetting[]
}

interface IScenarioUpdate {
    ids_camera: number[],
    settings: ISettingUpdate[]
}

interface IGroup {
    id: number,
    system_name: string,
    name: string,
    sort_order: number
}

export const ScenarioAPI = {
    getAll: async (): APIReturn<Array<IScenario>> => {
        // [GET] /api/v1/scenarios/
        return await API.get<Array<IScenario>>(`${resource}/`);
    },

    update: async(id: number, data: IScenarioUpdate): APIReturn<IScenario> => {
        // [PUT] /api/v1/scenarios/:id/
        return await API.put<IScenario>(`${resource}/${id}/`, data);
    },

    groupAll: async(): APIReturn<Array<IGroup>> => {
        // [GET] /api/v1/scenarios/groups/
        return await API.get<Array<IGroup>>(`${resource}/groups/`);
    }
}
