<template>
    <div class="flex flex-col w-full justify-center items-center">
        <div class="loading-spinner" :style="styleList"></div>

        <div :class="labelClassList">
            <slot/>
        </div>
    </div>
</template>

<script>

export default {
    name: "LoadingSpinner",

    props: {
        size: {
            type: Number,
            default: 48
        },

        textSize: {
            type: String,
            default: 'lg'
        },

        inline: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        styleList() {
            const sizeStr = `${this.size}px`;

            return {
                width: sizeStr,
                height: sizeStr
            }
        },

        labelClassList() {
            return [ 
                `text-${this.textSize}`, 'font-bold', 'text-second',
                {
                    'spinner-label': !this.inline
                }
            ];
        }
    }
};
</script>

<style>
.spinner-label {
    margin-top: 1rem;
}
</style>
