import {
    CameraAPI,
    CameraAttrAPI,
    CameraRefsAPI,
    CameraZoneAPI,
    RecorderAPI,
} from "@/api/camera.api.ts";
import Vue from "vue";

const state = {
    camera_list: [],
    camera_selected_id: 0,

    attr_list: [], // TODO: rename to camera_attr_list

    recorder_list: [],
    recorder_selected_id: 0,
    recorder_list_opened: {},

    zone_list: [],

    zone_selected_id: 0,
    zone_type_list: [],

    zone_attr_list: [],

    //  new Map() Не поддерживается в Vue 2. Суппорт добавлися только в Vue 3.. Ждёмс. https://medium.com/the-vue-point/plans-for-the-next-iteration-of-vue-js-777ffea6fabf
    // Пока что используем простой объект для этого.
    image_map: {},

    zone_edit_modal: {
        id_camera: 0,
        show: false,
    },

    is_zone_create_visible: false,
    is_zone_edit_data_visible: false,

    is_zone_edit_mode: false,
    is_zone_edit_cancel: false,
    is_zone_edit_save: false,

    ref_timezone_list: [],
};

const getters = {
    cameraList: (state) => state.camera_list,
    cameraSelectedId: (state) => state.camera_selected_id,

    recorderList: (state) => state.recorder_list,
    recorderSelectedId: (state) => state.recorder_selected_id,
    getOpenedRecorderList: (state) => state.recorder_list_opened,

    attrList: (state) => state.attr_list,

    zoneList: (state) => state.zone_list,
    zoneSelectedId: (state) => state.zone_selected_id,
    zoneTypeList: (state) => state.zone_type_list,
    zoneAttrList: (state) => state.zone_attr_list,

    imageMap: (state) => state.image_map,

    zoneEditModal: (state) => state.zone_edit_modal,
    isZoneCreateVisible: (state) => state.is_zone_create_visible,
    isZoneEditDataVisible: (state) => state.is_zone_edit_data_visible,

    isZoneEditMode: (state) => state.is_zone_edit_mode,
    isZoneEditCancel: (state) => state.is_zone_edit_cancel,
    isZoneEditSave: (state) => state.is_zone_edit_save,

    refTimezoneList: (state) => state.ref_timezone_list,
};

const actions = {
    async fetchCameraList(context) {
        const response = await CameraAPI.cameraGetAll();

        if (response.status !== 200) {
            console.log("fetchCameraList: Something went wrong", response);
            return;
        }

        context.commit("CAMERA_LIST_SET", response.data);
    },

    async cameraCreate(context, data) {
        const response = await CameraAPI.cameraCreate(data);

        if (response.status !== 200) {
            console.log("cameraCreate: went wrong", response);
            return false;
        }

        context.commit("CAMERA_APPEND", response.data);
        return true;
    },

    async cameraUpdate(context, { id_camera, data }) {
        const response = await CameraAPI.cameraUpdate(id_camera, data);

        if (response.status !== 200) {
            console.log("cameraUpdate went wrong", response);
            return false;
        }

        context.commit("CAMERA_UPDATE", {
            id_camera: id_camera,
            data: response.data,
        });
        return true;
    },

    async cameraDelete(context, id_camera) {
        const response = await CameraAPI.cameraDelete(id_camera);

        if (response.status !== 200) {
            console.log("cameraDelete: went wrong", response);
            return;
        }

        context.commit("CAMERA_DELETE", id_camera);
    },

    async cameraAttrUpdate(_, { id_camera, data }) {
        const response = await CameraAttrAPI.cameraAttrUpdate(id_camera, data);

        if (response.status !== 200) {
            console.log("cameraAttrUpdate went wrong", response);
            return false;
        }

        return true;
    },

    async fetchRecorderList(context) {
        const response = await RecorderAPI.recorderGetAll();

        if (response.status !== 200) {
            console.log("fetchRecorderList: Something went wrong", response);
            return;
        }

        context.commit("RECORDER_LIST_SET", response.data);
    },

    async recorderCreate({ commit }, payload) {
        const response = await RecorderAPI.recorderCreate(payload);

        if (response.status !== 200) {
            console.log("recorderCreate something went wrong", response);
            return;
        }

        commit("RECORDER_LIST_APPEND", response.data);
        commit("RECORDER_SELECTED_ID", response.data.id);
    },

    async recorderUpdate(context, { id, data }) {
        const response = await RecorderAPI.recorderUpdate(id, data);

        if (response.status !== 200) {
            console.log("recorderUpdate something went wrong", response);
            return;
        }

        context.commit("RECORDER_UPDATE", { id, data: response.data });
    },

    async recorderDelete(context, id) {
        const response = await RecorderAPI.recorderDelete(id);

        if (response.status !== 200) {
            console.log("recorderDelete something went wrong", response);
            return;
        }

        context.commit("RECORDER_UPDATE", {
            id,
            data: { deleted: true },
        });
    },

    toggleOpenedRecorderList(context, id) {
        context.commit("TOGGLE_RECORDER_LIST_OPENED", id);
    },

    async fetchAttrList(context) {
        const response = await CameraAttrAPI.attrGetAll();
        context.commit("ATTR_LIST_SET", response.data);
    },

    async attrCreate(context, data) {
        const response = await CameraAttrAPI.attrCreate(data);

        if (response.status !== 200) {
            console.log("attrCreate: Something went wrong", response);
            return;
        }

        await context.dispatch("fetchAttrList");
    },

    async attrUpdate(context, { id, data }) {
        const response = await CameraAttrAPI.attrUpdate(id, data);

        if (response.status !== 200) {
            console.log("attrUpdate: Something went wrong", response);
            return;
        }

        await context.dispatch("fetchAttrList");
    },

    async attrDelete(context, id) {
        const response = await CameraAttrAPI.attrDelete(id);

        if (response.status !== 200) {
            console.log("attrDelete: Something went wrong", response);
            return;
        }

        await context.dispatch("fetchAttrList");
    },

    // TODO: :DeleteAfterModuleRegister Временное решение пока не появится регистрацией модулей которое описывается
    // в файле SettingsRoles.vue
    clearData(context) {
        context.commit("CLEAR_DATA");
    },

    zoneEditModalShow(context, id_camera) {
        context.commit("ZONE_EDIT_MODAL_SHOW", id_camera);
    },

    zoneEditModalClose(context) {
        context.commit("ZONE_EDIT_MODAL_CLOSE");
    },

    async fetchCameraImage(context, id_camera) {
        const response = await CameraAPI.cameraImageGet(id_camera);

        switch (response.status) {
            case 200:
                context.commit("CAMERA_IMAGE_SET", {
                    id_camera: id_camera,
                    image: response.data,
                });
                break;

            case 204:
                break;

            default:
                console.logs("fetchCameraImage went wrong", response);
                break;
        }
    },

    async fetchZoneTypeList(context) {
        const response = await CameraRefsAPI.zoneTypeGetAll();

        switch (response.status) {
            case 200:
                context.commit("ZONE_TYPE_SET", response.data);
                break;

            default:
                console.log("fetchZoneTypeList went wrong", response);
                break;
        }
    },

    zoneSelectedIdSet(context, value) {
        context.commit("ZONE_SELECTED_ID_SET", value);
    },

    zoneCreateVisibleSet({ commit }, value) {
        commit("ZONE_CREATE_VISIBLE_SET", value);
    },

    async fetchZoneList({ commit }, { id_camera, deleted }) {
        const response = await CameraZoneAPI.zoneGetAll({
            id_camera: id_camera,
            deleted: deleted,
        });

        switch (response.status) {
            case 200:
                commit("ZONE_LIST_SET", response.data);
                break;

            default:
                console.log("fetchZonelist: something went wrong", response);
        }
    },

    async zoneCreate({ commit }, payload) {
        const response = await CameraZoneAPI.zoneCreate(payload);

        if (response.status !== 200) {
            console.log("Zone create something went wrong");
            return;
        }

        commit("ZONE_LIST_APPEND", response.data);
        commit("ZONE_SELECTED_ID_SET", response.data.id);
    },

    async zoneUpdate(context, { id_zone, data }) {
        const response = await CameraZoneAPI.zoneUpdate(id_zone, data);

        if (response.status !== 200) {
            console.log("Zone update something went wrong", response);
            return;
        }

        context.commit("ZONE_UPDATE", { id_zone, data: response.data });
    },

    async zoneDelete(context, id_zone) {
        const response = await CameraZoneAPI.zoneDelete(id_zone);

        if (response.status !== 200) {
            console.log("Zone delete something went wrong", response);
            return;
        }

        context.commit("ZONE_DELETE", id_zone);
    },

    zoneListClear({ commit }) {
        commit("ZONE_LIST_CLEAR");
    },

    isZoneEditModeSet(context, value) {
        context.commit("IS_ZONE_EDIT_MODE_SET", value);
    },
    isZoneEditCancelSet(context, value) {
        context.commit("IS_ZONE_EDIT_CANCEL_SET", value);
    },

    isZoneEditSaveSet(context, value) {
        context.commit("IS_ZONE_EDIT_SAVE_SET", value);
    },

    async zoneAttrListFetch(context) {
        const response = await CameraZoneAPI.zoneAttrGetAll();
        context.commit("ZONE_ATTR_LIST_SET", response.data);
    },

    async zoneAttrCreate(context, data) {
        const response = await CameraZoneAPI.zoneAttrCreate(data);

        if (response.status !== 200) {
            console.log("zoneAttrCreate: Something went wrong", response);
            return;
        }

        await context.dispatch("zoneAttrListFetch");
    },

    async zoneAttrUpdate(context, { id, data }) {
        const response = await CameraZoneAPI.zoneAttrUpdate(id, data);

        if (response.status !== 200) {
            console.log("zoneAttrUpdate: Something went wrong", response);
            return;
        }

        await context.dispatch("zoneAttrListFetch");
    },

    async zoneAttrDelete(context, id) {
        const response = await CameraZoneAPI.zoneAttrDelete(id);

        if (response.status !== 200) {
            console.log("attrDelete: Something went wrong", response);
            return;
        }

        await context.dispatch("zoneAttrListFetch");
    },

    async fetchRefTimezone(context) {
        const response = await CameraRefsAPI.timezoneGetAll();

        if (response.status !== 200) {
            console.log("fetchRefTimezone: Something went wrong", response);
            return;
        }

        context.commit("REF_TIMEZONE_LIST_SET", response.data);
    },
};

const mutations = {
    CAMERA_LIST_SET(state, payload) {
        state.camera_list = [...payload];
        state.camera_selected_id = 0;
    },

    CAMERA_APPEND(state, payload) {
        state.camera_list.push({ ...payload });
    },

    CAMERA_UPDATE(state, { id_camera, data }) {
        let item = state.camera_list.find((t) => t.id === id_camera);

        if (!item) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === "id") return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },

    CAMERA_DELETE(state, id_camera) {
        const item = state.camera_list.find((t) => t.id === id_camera);
        item.deleted = true;

        state.camera_selected_id = 0;
    },

    CAMERA_SELECTED_SET(state, value) {
        state.camera_selected_id = value;
    },

    RECORDER_LIST_SET(state, payload) {
        state.recorder_list = [...payload];
    },

    RECORDER_SELECTED_ID(state, value) {
        state.recorder_selected_id = value;
    },

    RECORDER_LIST_APPEND(state, payload) {
        state.recorder_list.push({ ...payload });
    },

    RECORDER_UPDATE(state, { id, data }) {
        const item = state.recorder_list.find((t) => t.id === id);

        if (!item) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === "id") return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },
    TOGGLE_RECORDER_LIST_OPENED(state, id) {
        if (!state.recorder_list_opened[id]) {
            state.recorder_list_opened[id] = true;
            return;
        }
        state.recorder_list_opened = {
            ...state.recorder_list_opened,
            [id]: !state.recorder_list_opened[id],
        };
    },

    CLEAR_DATA(state) {
        state.camera_list = [];
        state.camera_selected_id = 0;
        state.recorder_list = [];
        state.recorder_selected_id = 0;
        state.attr_list = [];
        state.image_map = {};
        state.zone_selected_id = 0;
        state.zone_type_list = [];

        state.zone_edit_modal = {
            id_camera: 0,
            show: false,
        };
    },

    ATTR_LIST_SET(state, payload) {
        state.attr_list = [...payload];
    },

    ZONE_EDIT_MODAL_SHOW(state, id_camera) {
        state.zone_edit_modal.show = true;
        state.zone_edit_modal.id_camera = id_camera;
    },

    ZONE_EDIT_MODAL_CLOSE(state) {
        state.zone_edit_modal.show = false;
        state.zone_edit_modal.id_camera = 0;
    },

    CAMERA_IMAGE_SET(state, { id_camera, image }) {
        // state.image_map.set(id_camera, image);  // TODO: Vue3
        Vue.set(state.image_map, id_camera, image);
    },

    ZONE_TYPE_SET(state, payload) {
        state.zone_type_list = [...payload];
    },

    ZONE_SELECTED_ID_SET(state, value) {
        state.zone_selected_id = value;
    },

    ZONE_CREATE_VISIBLE_SET(state, value) {
        state.is_zone_create_visible = value;
    },

    ZONE_EDIT_DATA_VISIBLE_SET(state, value) {
        state.is_zone_edit_data_visible = value;
    },

    ZONE_LIST_SET(state, payload) {
        state.zone_list = [...payload];
    },

    ZONE_LIST_APPEND(state, payload) {
        state.zone_list.push({ ...payload });
    },

    ZONE_LIST_CLEAR(state) {
        state.zone_list = [];
    },

    ZONE_UPDATE(state, { id_zone, data }) {
        const item = state.zone_list.find((t) => t.id === id_zone);

        if (!item) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === "id") return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },

    ZONE_DELETE(state, id_zone) {
        const index = state.zone_list.findIndex((t) => t.id === id_zone);
        state.zone_list.splice(index, 1);

        state.zone_selected_id = 0;
    },

    IS_ZONE_EDIT_MODE_SET(state, value) {
        state.is_zone_edit_mode = value;
    },

    IS_ZONE_EDIT_CANCEL_SET(state, value) {
        state.is_zone_edit_cancel = value;
    },

    IS_ZONE_EDIT_SAVE_SET(state, value) {
        state.is_zone_edit_save = value;
    },

    ZONE_ATTR_LIST_SET(state, payload) {
        state.zone_attr_list = [...payload];
    },

    IS_MODAL_CAMERA_CREATE_VISIBLE(state, value) {
        state.is_modal_camera_create_visible = value;
    },

    REF_TIMEZONE_LIST_SET(state, payload) {
        state.ref_timezone_list = [...payload];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
