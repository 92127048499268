// Полезные хелперы для работы с массивами

/**
 * Копирует объект vue создавая голые данные без обсёрверов и референсов к объектам
 * @param value
 * @returns {any}
 */
export function cloneVueObject(value) {
    return JSON.parse(JSON.stringify(value));
}

/**
 * Изменяет массив вложенных объектов с детьми (children) и делает его
 * плоским
 *
 * @param tree
 * @returns {*|*[]}
 */
export function flatArrayWithChildren(tree) {
    // @Incomplete: В этом хелпере в результате нужно убирать из массива ...item
    // его текущих детей.
    return tree? tree.reduce((result, item) => [
            ...result,
            { ...item},
            ...flatArrayWithChildren(item.children)
    ], []) : [];
}

/**
 * Получение всех идентификатов id во вложенном массиве с детьми
 *
 * @param tree
 * @returns {*[]}
 */
export function getKeysWithChildren(tree) {
    let keys = [];
    for (let item of tree) {
        keys.push(item.id);

        const children = item.children;
        if (children.length > 0) keys.push(...getKeysWithChildren(children));
    }

    return keys;
}

/**
 * Являются ли два массива одинаковыми
 * @param a Первый массив
 * @param b Второй массив
 * @returns {boolean} Результат
 */
export function arrayIsEqual(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}
