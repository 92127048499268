import { ScenarioAPI } from "@/api/scenario.api";
import { CameraAPI } from "@/api/camera.api";
import { EventTypeAPI } from "@/api/event.api";

const state = {
    scenario_list: [],
    scenario_selected_id: [],

    scenario_modal_visible: false,

    group_list: [],

    camera_list: [],
    event_type_list: []
}

const getters = {
    scenarioList: state => state.scenario_list,
    scenarioSelectedId: state => state.scenario_selected_id,

    scenarioModalVisible: state => state.scenario_modal_visible,

    groupList: state => state.group_list,

    cameraList: state => state.camera_list,
    eventTypeList: state => state.event_type_list,
}

const actions = {
    async fetchScenarioList(context) {
        const response = await ScenarioAPI.getAll();

        if (response.status !== 200) {
            console.log("fetchScenarioList: Something went wrong", response);
            return;
        }

        context.commit('SCENARIO_LIST_SET', response.data)
    },

    async fetchGroupList(context) {
        const response = await ScenarioAPI.groupAll();

        if (response.status !== 200) {
            console.log("fetchScenarioList: Something went wrong", response);
            return;
        }

        context.commit('GROUP_LIST_SET', response.data)
    },

    async fetchParamData(context) {
        // Выгружаем event_type и camera для отображение в списке фильтров
        {
            const response = await CameraAPI.cameraGetAll({deleted: false});

            if (response.status !== 200) {
                console.log("fetchParamData: Something went wrong", response)
                return;
            }

            context.commit('CAMERA_LIST_SET', response.data);
        }
        {
            const response = await EventTypeAPI.eventTypeGetAll({ deleted: false } );

            if (response.status !== 200) {
                console.log("fetchParamData: Something went wrong", response)
                return;
            }

            context.commit('EVENT_TYPE_LIST_SET', response.data);
        }
    },

    async scenarioUpdate(context, {id, data}) {
        const response = await ScenarioAPI.update(id, data);

        if (response.status !== 200) {
            console.log("scenarioUpdate: Something went wrong", response)
            return false;
        }

        context.commit("SCENARIO_UPDATE", {id, data: response.data});
        return true
    }
}

const mutations = {
    SCENARIO_LIST_SET(state, payload) {
        state.scenario_list = [...payload];
    },

    SCENARIO_UPDATE(state, { id, data }) {
        let item = state.scenario_list.find(t => t.id === id);
        if (!item) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'id') return;

            if (item[key] !== value) {
                if (Array.isArray(value)) {
                    item[key] = [...value]
                    return
                }
                
                if (value instanceof 'object') {
                    item[key] = {...value}
                    return
                }

                item[key] = value;
            }
        });
    },

    GROUP_LIST_SET(state, payload) {
        state.group_list = [...payload]
        state.group_list.sort((a, b) => a.sort_order - b.sort_order);
    },

    CAMERA_LIST_SET(state, payload) {
        state.camera_list = [...payload];
    },

    EVENT_TYPE_LIST_SET(state, payload) {
        state.event_type_list = [...payload];
    },

    SCENARIO_SELECTED_ID(state, value) {
        state.scenario_selected_id = value;
    },

    SCENARIO_MODAL_VISIBLE(state, value) {
        state.scenario_modal_visible = value;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
