
<template>
    <v-dialog
        v-model="eventChangedPlateModal.show"
        max-width="900"
        @keydown.esc="closeModal"
        @click:outside="closeModal"
    >
        <div class="flex flex-col modal-content px-4 py-2">
            <!-- Header -->
            <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row text-lg font-bold">
                    История изменения номера:
                </div>

                <ButtonIcon
                    :size="20"
                    color="rgb(255,255,255,0.8)"
                    @click="closeModal"
                >
                    fas fa-times

                </ButtonIcon>
            </div>

            <Divider class="my-2"/>

            <!-- Form -->
            <div class="flex flex-col pb-2">
                <!-- History Information -->
                <div class="flex flex-col">
                   <div
                        class="flex flex-col relative pb-2"
                        v-for="data in data"
                        :key="data.date"
                   >
                        <div class="flex absolute timeline-dot"></div>

                            <!--/* <v-icon size="12" color="#ADAFB3">fas fa-circle</v-icon> */-->
                        <div class="flex items-center rounded px-2 py-1 ml-6">
                            <div class="flex font-bold text-sm text-second mb-2">
                                {{ data.date | formatDateTime }}
                            </div>
                        </div>

                        <div class="flex flex-col px-2 ml-6">
                            <div class="flex text-sm font-bold bg-item rounded px-2 py-2 ">
                                {{ data.old }} => {{ data.new }}
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
// import { AutoOrderAPI } from '@/api/auto.api';
import { mapGetters } from 'vuex';
import ButtonIcon from '../base/button/ButtonIcon.vue';
import Divider from '../base/Divider.vue';
import { AutoAPI } from "@/api/auto.api.ts";

export default {
    name: "AutoChangedPlateModal",

    components: {
        ButtonIcon,
        Divider
    },

    data: () => ({
        data: []
    }),

    computed: {
        ...mapGetters('auto', ['orderSelectedId', 'autoOrders', 'autoSelectedId']),
        ...mapGetters('base', ['getTranslate']),
        ...mapGetters('event', ['eventChangedPlateModal']),
    },

    watch: {
        eventChangedPlateModal:{
            deep: true,
            handler: function(value) {
                this.loadHistory(value.id_event);
            }
        }
    },
    created(){
    },
    methods: {

        async initModal() {
            console.log(this.eventChangedPlateModal);
        },

        closeModal() {
            this.$store.dispatch("event/closeEventChangedPlateModal")
        },

        async loadHistory(id_event){
            if (id_event === 0) return;
            const response = await AutoAPI.eventChangedPlate({id_event: id_event});
            this.data = response.data;
        },

        getActionByName(value) {
            switch(value) {
                case 'create':
                    return 'Создано'

                case 'delete':
                    return 'Удалено'

                default:
                    return 'Неизвестно'
            }
        }
    }
}
</script>

<style scoped>
.modal-content {
    background-color: var(--main-color);
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.timeline-dot {
    position: absolute;
    top: 6px;
    left: 4px;
    bottom: 0;
}

.timeline-dot:before {
    content: "";
    border: 3px solid transparent;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 4px;
    left: 0;
    display: block;
    background-color: #a78bfa;
}

.timeline-dot:after {
    content: "";
    position: absolute;
    display: block;
    background-color: #a78bfa;
    width: 3px;
    top: 24px;
    bottom: 0;
    left: 6px;
}
</style>
