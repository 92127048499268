import UserAPI from "@/api/user.api.ts";

const state = {
    user_list: []
}

const getters = {
    userList: state => state.user_list,
};

const actions = {
    async fetchUserList(context) {
        const response = await UserAPI.userGetAll();
        let result = response.data;

        if (response.status !== 200) {
            result = [];
        }
        context.commit('USER_LIST_SET', result);
    },

    userAppend(context, payload) {
        context.commit("USER_APPEND", payload);
    },

    userUpdate(context, {id_user, data}) {
        context.commit('USER_UPDATE', {id_user, data});
    },

    clearData(context) {  // :DeleteAfterModuleRegister
        context.commit('CLEAR_DATA');
    }
};

const mutations = {
    USER_LIST_SET(state, payload) {
        state.user_list = [...payload];
    },

    USER_APPEND(state, payload) {
        state.user_list.push({...payload});
    },

    USER_UPDATE(state, {id_user, data}) {
        let item = state.user_list.find(t => t.id === id_user);

        if (!item) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'id') return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },

    CLEAR_DATA(state) {
        state.user_list = [];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
