import { StomaAPI } from "@/api/stoma.api";
import { timeFromISO } from "@/utils/time";

const state = {
    is_stoma_loading: false,

    stoma_list: [],

    stoma_selected_id: 0,

    stoma_count: 0,

    /** Чанки данных */
    stoma_chunk_limit: 25,
    stoma_chunk_offset: 0,

    stoma_search_text: "",

    is_show_all: false,
    is_search_ignore_time: false,

    info_list: [],

    // Список врачей
    doctor_list: [],
    search_doc_id: 0,

    // Список камер
    cam_list: [],
    search_cabinet_cam: 0
}

const getters = {
    isStomaLoading: state => state.is_stoma_loading,
    stomaList: state => state.stoma_list,

    stomaSelectedId: state => state.stoma_selected_id,
    stomaCount: state => state.stoma_count,

    stomaChunkLimit: state => state.stoma_chunk_limit,
    stomaChunkOffset: state => state.stoma_chunk_offset,

    stomaSearchText: state => state.stoma_search_text,

    isShowAll: state => state.is_show_all,
    isSearchIgnoreTime: state => state.is_search_ignore_time,

    infoList: state => state.info_list,

    doctorList: state => state.doctor_list,
    searchDocId: state => state.search_doc_id,

    camList: state => state.cam_list,
    searchCabinetCam: state => state.search_cabinet_cam
}

const actions = {
    async fetchStomaList(context, {load_info}) {
        if (load_info) {
            context.commit('SET_STOMA_LOADING', true);
        }

        let options = await context.dispatch('base/baseOptions', null, {root: true});

        if (context.getters.isSearchIgnoreTime) {
            const new_bt = timeFromISO(new Date('2000-01-01').toISOString().substr(0, 10)).toFormat("yyyy-LL-dd HH:mm");
            const new_et = timeFromISO(new Date().toISOString().substr(0, 10)).toFormat("yyyy-LL-dd HH:mm");

            options.begin_time = new_bt;
            options.end_time = new_et;
        }

        options = {
            ...options,
            offset: context.getters.stomaChunkOffset,
            limit: context.getters.stomaChunkLimit,
            load_info: load_info,

            show_all: context.getters.isShowAll
        }

        if (context.getters.stomaSearchText) {
            options = {
            ...options,
                patient: context.getters.stomaSearchText,
            }
        }

        if (context.getters.searchDocId) {
            options = {
            ...options,
                doc_id: context.getters.searchDocId,
            }
        }

        if (context.getters.searchCabinetCam) {
            options = {
            ...options,
                cabinet_cam: context.getters.searchCabinetCam,
            }
        }

        const response = await StomaAPI.stomaGetAll(options);
        const payload = response.data;

        if (load_info) {
            context.commit('STOMA_LIST_SET', payload.data);
            context.commit("STOMA_COUNT_SET", payload.count);
            context.commit('base/SET_IS_DAY_CLOSED', payload.closed, {root: true})

            context.commit('SET_STOMA_LOADING', false);
        } else {
            context.commit('STOMA_LIST_APPEND', payload.data);

        }
    },

    async fetchStomaPeriod(context) {
        let options = await context.dispatch('base/baseOptionsPeriod', null, {root: true})

        options = {
            ...options,
            show_all: context.getters.isShowAll
        }

        const response = await StomaAPI.periodGet(options);

        await context.dispatch(
            "core/setDataTimePeriod",
            response.data.time_period,
            {root: true}
        );
    },

    async fetchEventList(context, {load_info}) {
        const currentItemId = context.getters.stomaSelectedId;

        if (!currentItemId) {
            await context.dispatch('event/clearEventList', null, {root: true});
            return;
        }

        const currentItem = context.getters.stomaList.find(t => t.id === currentItemId);

        if (!currentItem) {
            console.log("Error fetchEventList: Can't find item in Stoma list with id", currentItemId);
            await context.dispatch('event/clearEventList', null, {root: true});
            return;
        }

        if (load_info) {
            await context.dispatch('event/setEventLoading', true, {root: true});
            await context.dispatch('event/setEventChunkOffset', 0, {root: true});
        }

        let options = await context.dispatch('base/baseOptions', null, {root: true});

        options = {
            ...options,
            offset: context.rootGetters['event/eventChunkOffset'],
            limit: context.rootGetters['event/eventChunkLimit'],
            load_info: load_info
        }

        const response = await StomaAPI.eventGetAll(currentItem.id, options);

        const payload = response.data;

        if (load_info) {
            context.dispatch('event/setEventList', payload.data, {root: true});
            context.dispatch('event/setEventCount', payload.count, {root: true});
            context.dispatch('event/setEventLoading', false, {root: true});
        } else {
            context.dispatch('event/eventListAppend', payload.data, {root: true});
        }
    },

    async fetchInfoList(context) {
        const currentItemId = context.getters.stomaSelectedId;

        if (!currentItemId) {
            return;
        }

        const currentItem = context.getters.stomaList.find(t => t.id === currentItemId);

        if (!currentItem) {
            console.log("Error fetchInfoList: Can't find item in Stoma list with id", currentItemId);
            return;
        }

        const response = await StomaAPI.infoGet(currentItem.id);

        context.commit("INFO_LIST_SET", response.data);
    },

    async fetchDoctorList(context) {
        const response = await StomaAPI.doctorsGetAll()
        context.commit('DOCTOR_LIST_SET', response.data)
    },

    async fetchCamList(context) {
        const response = await StomaAPI.cabinetCamGetAll()

        context.commit('CAM_LIST_SET', response.data)
    },

    infoListClear(context) {
        context.commit("INFO_LIST_CLEAR");  // TODO:
    },

    stomaListClear(context) {
        context.commit("STOMA_LIST_CLEAR");
    },

    stomaSelectedSet(context, value) {
        context.commit("STOMA_SELECTED_SET", value);
    },

    stomaChunkOffsetSet(context, value) {
        context.commit('STOMA_CHUNK_OFFSET_SET', value);
    },

    stomaChunkLimitSet(context, value) {
        context.commit('STOMA_CHUNK_LIMIT_SET', value);
    },

    stomaSearchTextSet(context, value) {
        context.commit('STOMA_SEARCH_TEXT_SET', value);
    },

    searchDocIdSet(context, value) {
        context.commit('SEARCH_DOC_ID_SET', value);
    },

    searchCabinetCamSet(context, value) {
        context.commit('STOMA_CABINET_CAM_SET', value);
    },

    isShowAllSet(context, value) {
        context.commit('IS_SHOW_ALL_SET', value);
    },

    isSearchIgnoreTimeSet(context, value) {
        context.commit('IS_SEARCH_IGNORE_TIME_SET', value);
    },

    stomaCountSet(context, value) {
        context.commit('STOMA_COUNT_SET', value);
    },

    async eventSetGlobalState(context, id_state) {
        const currentItemId = context.getters.stomaSelectedId;

        if (!currentItemId) {
            return;
        }

        const currentItem = context.getters.stomaList.find(t => t.id === currentItemId);

        if (!currentItem) {
            console.log("Error eventSetGlobalState: Can't find item in Stoma list with id", currentItemId);
            return;
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            id_state: id_state
        }

        const response = await StomaAPI.eventSetGlobalState(currentItem.id, options);

        if (response.status === 403) {
            await context.dispatch("core/addNotificationError", {
                    header: "Закрытое событие",
                    message:
                        "Вы не можете обновлять закрытые события.",
                    timeout: 3000,
                }, { root: true });
            return;
        }

        if (response.status !== 200) {
            await context.dispatch("core/addNotificationError", {
                header: "Ошибка обновления события",
                message:
                    "Произошла какая-то ошибка с обновлением события...",
                timeout: 2500,
            }, { root: true });
            return
        }

        // Сбрасываем текущее событие, чтобы не было проблем с родительским
        await context.dispatch("event/clearEventParentId", null, { root: true});
        await context.dispatch('event/setEventSelected', 0, { root: true});
        await context.dispatch('fetchEventList', { load_info: true });
    }
}

const mutations = {
    SET_STOMA_LOADING(state, value) {
        state.is_stoma_loading = value;
    },

    STOMA_LIST_SET(state, payload) {
        state.stoma_list = [ ...payload ];

        if (state.stoma_list.length > 0) {
            state.stoma_selected_id = state.stoma_list[0].id;
        } else {
            state.stoma_selected_id = 0;
        }
    },

    STOMA_LIST_APPEND(state, payload) {
        state.stoma_list = [
            ...state.stoma_list,
            ...payload
        ]
    },

    STOMA_LIST_CLEAR(state) {
        state.stoma_list = [];
    },

    INFO_LIST_SET(state, payload) {
        state.info_list = [...payload]
    },

    INFO_LIST_CLEAR(state) {
        state.info_list = [];
    },

    DOCTOR_LIST_SET(state, payload) {
        state.doctor_list = [...payload]
    },

    DOCTOR_LIST_CLEAR(state) {
        state.doctor_list = [];
    },

    CAM_LIST_SET(state, payload) {
        state.cam_list = [...payload]
    },

    CAM_LIST_CLEAR(state) {
        state.cam_list = [];
    },

    SEARCH_DOC_ID_SET(state, value) {
        state.search_doc_id = value;
    },

    STOMA_CABINET_CAM_SET(state, value) {
        state.search_cabinet_cam = value;
    },

    STOMA_SELECTED_SET(state, value) {
        state.stoma_selected_id = value;
    },

    STOMA_COUNT_SET(state, value) {
        state.stoma_count = value;
    },

    STOMA_CHUNK_OFFSET_SET(state, value) {
        state.stoma_chunk_offset = value;
    },

    STOMA_CHUNK_LIMIT_SET(state, value) {
        state.stoma_chunk_limit = value;
    },

    STOMA_SEARCH_TEXT_SET(state, value) {
        state.stoma_search_text = value;
    },

    IS_SHOW_ALL_SET(state, value) {
        state.is_show_all = value;
    },

    IS_SEARCH_IGNORE_TIME_SET(state, value) {
        state.is_search_ignore_time = value;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
