<template>
    <div
        class="button-icon"
        :class="classList"
        :title="title"
        @click="onClick($event)"
    >
        <v-icon
            :size="size"
            :color="color"
        >
            <slot/>
        </v-icon>
    </div>
</template>

<script>
export default {
    name: "ButtonIcon",

    props: {
        title: {
            type: String,
            default: ""
        },

        size: {
            type: Number,
            default: 18
        },

        color: {
            // TODO: make it rgb(a) as well
            type: String,
            default: "#FBBF24"
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        classList() {
            return {
                'button-icon-disabled': this.disabled
            }
        }
    },

    methods: {
        onClick(event) {
            this.$emit('click', event)
        }
    }
}
</script>

<style scoped>

.button-icon {
    @apply cursor-pointer;
    @apply inline-flex;
    @apply items-center;
    @apply justify-center;
    @apply p-1;
}

.button-icon:hover {
    background-color: var(--white-alpha-04);
    @apply rounded;
}

.button-icon-disabled {
    @apply cursor-default;
}

.button-icon-disabled:hover {
    background-color: inherit;
}

.button-icon-disabled > i {
    color: var(--secondary) !important;
}

</style>
