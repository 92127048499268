const userColors = [
    "#1abc9c", "#d38312", "#3498db", "#9b59b6",
    "#34495e", "#16a085", "#27ae60", "#2980b9",
    "#8e44ad", "#2c3e50", "#e4b80f", "#e67e22",
    "#e74c3c", "#7c8b8c", "#d38312", "#d35400",
    "#c0392b", "#3752b9", "#ab18ad", "#15918c"
]

export function getInitials(username) {
    const nameSplit = username.toUpperCase().split(".");
    const initials =
        nameSplit.length === 1
            ? nameSplit[0].charAt(0)
            : nameSplit[0].charAt(0) + nameSplit[1].charAt(0);

    const charIndex = initials.charCodeAt(0) - 64;
    const colorIndex = charIndex % 20;  // 20 is the length of userColors array. We don't use userColors.length because of a little optimization
    return {
        initials: initials,
        color: userColors[colorIndex]
    }
}
