
import API, { APIReturn } from "@/api";

const resource = "/shift";

interface IParamsDeleted {
    deleted: boolean;
}

interface IParams {
    begin_time: string;
    end_time: string;
    shift: string;
    limit: number;
    offset: number;
    id_filter: number;
    load_info: boolean;
}

interface IEventParams {
    begin_time: string;
    end_time: string;
    id_filter: number;
    id_parent: number;
    limit: number;
    offset: number;
    load_info: boolean;
}

interface IPeriodParams {
    begin_time: string;
    id_filter: number;
}

interface IEventSetState {
    id_state: number;
    begin_time: string;
    end_time: string;
    id_filter: number;
}

interface IDataInfo {
    closed: boolean;
    count: 0;
    data: IShift[];
}

interface IShift {
    id: number;
    shift_name: string;
    begin_time: number;
    end_time: number;
}

interface IShiftPeriod {
    time_period: string[];
}

interface IEvent {
    id: number;
    begin_time: number;
    end_time: number;
    id_camera: number;
    id_camera_zone: number;
    id_event_type: number;
    comment: string;
    id_state: number;
    deleted: boolean;
    closed: boolean;
    has_child: boolean;
    show_id_camera_zone: number;
}
interface IEventUpdate {
    id_state: number;
    begin_time: number;
    end_time: number;
    id_camera: number;
    id_camera_zone: number;
    id_event_type: number;
    comment: string;
    id_parent: number;
}

interface IShiftEvents {
    count: number;
    data: IEvent[];
}

interface IExportVideoParams {
    begin_time: string;
    end_time: string;
    id_filter: number;
    event_ids: number[];
}

export const ShiftAPI = {
    getAll: async (options: IParams): APIReturn<IDataInfo> => {
        // [GET] /v1/skipass/
        return await API.get<IDataInfo>(`${resource}/`, { params: options });
    },

    getPeriod: async (options: IPeriodParams): APIReturn<IShiftPeriod> => {
        // [GET] /v1/skipass/period/
        return await API.get<IShiftPeriod>(`${resource}/period/`, {
            params: options,
        });
    },

    getEventList: async (
        id: number,
        options: IEventParams
    ): APIReturn<IShiftEvents> => {
        // [GET] /v1/skipass/:id/events/
        return await API.get<IShiftEvents>(`${resource}/${id}/events/`, {
            params: options,
        });
    },

    eventSetGlobalState: async (
        id: number,
        options: IEventSetState
    ): APIReturn<string> => {
        // [GET] /v1/skipass/:id/events/set_state/
        return await API.put<string>(
            `${resource}/${id}/events/set_state/`,
            null,
            { params: options }
        );
    },

    skiUpdateEvent: async (
        id: number,
        id_event: number,
        data: IEventUpdate
    ): APIReturn<IEvent> => {
        // [PUT] v1/skipass/{id_skipass}/events/{id_event}/
        return await API.put<IEvent>(
            `${resource}/${id}/events/${id_event}`,
            data
        );
    },
    // TODO: types
    exportVideo: async (
        id: number,
        options: IExportVideoParams
    ): APIReturn<any> => {
        // [GET] /v1/skipass/:id/export/video/
        return await API.get<any>(`${resource}/${id}/export/video/`, {
            params: options,
            responseType: "blob",
        });
    },
};
