import { OzonAPI } from "@/api/ozon.api.ts";

const state = {
    is_data_loading: false,

    data_list: [],
    data_selected_id: 0,
    data_count: 0,

    status_list: [],

    /** Данные для поиска данных */
    search_external_id: -1,
    search_gate_name: "",
    search_warehouse: "",
    search_supplier_name: "",
    search_status_id: 0,

    // Опциональные значения

    show_all: false,

    /** Чанки данных */
    chunk_limit: 25,
    chunk_offset: 0,
}

const getters = {
    isDataLoading: state => state.is_data_loading,
    dataList: state => state.data_list,

    dataSelectedId: state => state.data_selected_id,
    dataCount: state => state.data_count,

    statusList: state => state.status_list,

    searchExternalId: state => state.search_external_id,
    searchGateName: state => state.search_gate_name,
    searchWarehouse: state => state.search_warehouse,
    searchSupplierName: state => state.search_supplier_name,
    searchStatusId: state => state.search_status_id,

    showAll: state => state.show_all,

    chunkLimit: state => state.chunk_limit,
    chunkOffset: state => state.chunk_offset,
}

const actions = {
    async fetchDataList(context, {load_info}) {
        if (load_info && !context.getters.isAutoLoading) {
            context.commit('IS_DATA_LOADING', true);
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,

            external_id: context.getters.searchExternalId || -1,
            gate_name: context.getters.searchGateName || "",
            warehouse: context.getters.searchWarehouse || "",
            supplier_name: context.getters.searchSupplierName || "",
            status_id: context.getters.searchStatusId || -1,

            show_all: +context.getters.showAll || 0,

            offset: context.getters.chunkOffset,
            limit: context.getters.chunkLimit,
            load_info: load_info,
        }

        const response = await OzonAPI.getAll(options);

        if (response.status !== 200) {
            console.log("fetchDataList: something went wrong", response);
            return;
        }

        const payload = response.data;

        if (load_info) {
            context.commit('DATA_LIST_SET', payload.data);
            context.commit('DATA_COUNT_SET', payload.count);

            context.commit('base/SET_IS_DAY_CLOSED', payload.closed,  { root :true});
            context.commit('IS_DATA_LOADING', false);
        }
        else {
            context.commit('DATA_LIST_APPEND', payload.data);
        }
    },

    async fetchDataPeriod(context) {
        let options = await context.dispatch('base/baseOptionsPeriod', null, { root: true });

        options = {
            ...options,

            external_id: context.getters.searchExternalId || -1,
            gate_name: context.getters.searchGateName || "",
            warehouse: context.getters.searchWarehouse || "",
            supplier_name: context.getters.searchSupplierName || "",
            status_id: context.getters.searchStatusId || -1,

            show_all: +context.getters.showAll || 0,
        }

        const response = await OzonAPI.getPeriod(options);
        if (response.status !== 200) {
            console.log("fetchDataPeriod: something went wrong", response);
            return;
        }

        await context.dispatch("core/setDataTimePeriod", response.data.time_period, { root: true });
    },

    async fetchEventList(context, {load_info}) {
        const currentId = context.getters.dataSelectedId;

        if (!currentId) {
            await context.dispatch('event/clearEventList', null, { root: true });
            return;
        }

        if (load_info) {
            await context.dispatch('event/setEventLoading', true, { root :true});
            await context.dispatch('event/setEventChunkOffset', 0, { root :true});
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });
        options = {
            ...options,
            offset: context.rootGetters['event/eventChunkOffset'],
            limit: context.rootGetters['event/eventChunkLimit'],
            load_info: load_info
        }

        const response = await OzonAPI.getEventList(currentId, options);

        if (response.status !== 200) {
            console.log("fetchEventList: something went wrong", response);
            return;
        }

        const payload = response.data;

        if (load_info) {
            await context.dispatch('event/setEventList', payload.data, { root: true });
            await context.dispatch('event/setEventCount', payload.count, { root: true });
            await context.dispatch('event/setEventLoading', false, { root :true});
        }
        else {
            await context.dispatch('event/eventListAppend', payload.data, { root: true });
        }
    },

    async eventSetGlobalState(context, id_state) {
        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            id_state: id_state
        }

        const response = await OzonAPI.eventSetGlobalState(
            context.getters.dataSelectedId,
            options
        );

        if (response.status === 403) {
            await context.dispatch("core/addNotificationError", {
                    header: "Закрытое событие",
                    message:
                        "Вы не можете обновлять закрытые события.",
                    timeout: 3000,
                }, { root: true });
            return;
        }

        if (response.status !== 200) {
            await context.dispatch("core/addNotificationError", {
                header: "Ошибка обновления события",
                message:
                    "Произошла какая-то ошибка с обновлением события...",
                timeout: 2500,
            }, { root: true });
            return;
        }

        // Сбрасываем текущее событие, чтобы не было проблем с родительским
        await context.dispatch("event/clearEventParentId", null, { root: true});
        await context.dispatch('event/setEventSelected', 0, { root: true});
        await context.dispatch('fetchEventList', { load_info: true });
    },

    async fetchStatusList(context) {
        const response = await OzonAPI.getStatusList();

        if (response.status !== 200) {
            await context.dispatch("core/addNotificationError", {
                header: "Ошибка получения статуса события",
                message:
                    "Произошла какая-то ошибка с получением статусов события...",
                timeout: 2500,
            }, { root: true });
            return;
        }

        await context.commit('STATUS_LIST_SET', response.data);
    }

}

const mutations = {
    IS_DATA_LOADING(state, value) {
        state.is_data_loading = value;
    },

    DATA_LIST_SET(state, payload) {
        state.data_list = [...payload];

        if (state.data_list.length > 0) {
            state.data_selected_id = state.data_list[0].id;
        } else {
            state.data_selected_id = 0;
        }
    },

    DATA_LIST_APPEND(state, payload) {
        state.data_list = [
            ...state.data_list,
            ...payload
        ]
    },

    DATA_LIST_CLEAR(state) {
        state.data_list = [];
    },

    DATA_COUNT_SET(state, value) {
        state.data_count = value;
    },

    DATA_SELECTED_SET(state, value) {
        state.data_selected_id = value;
    },

    CHUNK_OFFSET_SET(state, value) {
        state.chunk_offset = value;
    },

    SEARCH_SET_DATA(state, {search_external_id, search_gate_name, search_warehouse, search_supplier_name, search_status_id, show_all}) {
        state.search_external_id = search_external_id;
        state.search_gate_name = search_gate_name;
        state.search_warehouse = search_warehouse;
        state.search_supplier_name = search_supplier_name;
        state.search_status_id = search_status_id;
        state.show_all = show_all;
    },

    STATUS_LIST_SET(state, payload) {
        state.status_list = [...payload];
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}


