import API, {APIReturn} from '@/api';
import {IEvent, IEventParams, IGlobalState, IPeriod, IPeriodParams as BasePeriodParams} from "@/api/event.api";

const RESOURCE = "/stoma";

interface IStomaParams extends IEventParams {
    show_all: boolean,
    doc_id: number,
    cabinet_cam: number,
    patient: number
}

interface IStomaItem {
    id: number,
    id_patient: number,
    time_arrived: number,
    time_check_in: number,
    time_check_out: number,
    id_camera: number,
    planned_start_time: number,
    planned_end_time: number,
    status: string,
    doctor: string
}

interface IStomaList {
    count: number,
    closed: boolean,
    data: IStomaItem
}

interface IPeriodParams extends BasePeriodParams {
    show_all: boolean,
    doc_id: number,
    cabinet_cam: number,
    patient: number
}

interface ISimpleItem {
    id: number,
    name: string
}

interface IStomaEventList {
    count: number,
    data: IEvent
}

export const StomaAPI = {
    stomaGetAll: async (options: IStomaParams): APIReturn<IStomaList> => {
        // [GET] /v1/stoma/
        return await API.get<IStomaList>(`${RESOURCE}/`, { params: options });
    },

    periodGet: async (options: IPeriodParams): APIReturn<IPeriod> => {
        // [GET] /v1/stoma/period/
        return await API.get<IPeriod>(`${RESOURCE}/period/`, { params: options });
    },

    infoGet: async (id_item: number): APIReturn<Array<ISimpleItem>> => {
        // [GET] /v1/stoma/:id_item/info/
        return await API.get(`${RESOURCE}/${id_item}/info/`);
    },

    doctorsGetAll: async(): APIReturn<Array<ISimpleItem>> => {
        // [GET] /v1/stoma/ref/doctors/
        return await API.get<Array<ISimpleItem>>(`${RESOURCE}/ref/doctors/`);
    },

    cabinetCamGetAll: async(): APIReturn<Array<ISimpleItem>> => {
        // [GET] /v1/stoma/ref/cabinet_cams/
        return await API.get<Array<ISimpleItem>>(`${RESOURCE}/ref/cabinet_cams/`);
    },

    eventGetAll: async (id_item: number, options: IEventParams): APIReturn<IStomaEventList> => {
        // [GET] /v1/stoma/:id_item/events/
        return await API.get<IStomaEventList>(`${RESOURCE}/${id_item}/events/`, { params: options });
    },

    eventSetGlobalState: async(id_item: number, options: IGlobalState): APIReturn<string> => {
        // [GET] /v1/stoma/:id_item/events/set_state/
        return await API.put<string>(`${RESOURCE}/${id_item}/events/set_state/`, null,{ params: options });
    },
}
