<template>
    <div
        class="closing-day-overlay no-selection pointer-events-none"
        :class="classList"
    >
        <div class="flex flex-col w-full items-center justify-center">
            <LoadingSpinner/>
            <div class="text-white font-bold text-xl">Закрываем день...</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/core/LoadingSpinner";

export default {
    name: "ClosingDayOverlay",

    components: {
        LoadingSpinner
    },

    data: () => ({
        hidden: false
    }),

    computed: {
        ...mapGetters('base', ["isDayClosedLoading"]),

        classList() {
            return {
                'closing-day-overlay-hidden': !this.hidden
            }
        }
    },

    watch: {
        isDayClosedLoading(value) {
            this.hidden = value;
        }
    }
}
</script>

<style scoped>
.closing-day-overlay {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0.77;
    background-color: #212121;
    pointer-events: auto;
}

.closing-day-overlay-hidden {
    display: none;
}
</style>
