<template>
    <div class="text-sm max-w-full">
        <div
            v-for="node in node_list"
            :key="node.id"
            class="flex flex-col mb-1"
        >
            <v-tooltip
                bottom
                color="#242526"
                :open-delay="200"
                :nudge-bottom="-10"
                :disabled="shouldShowTooltip(node)"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div
                        class="tree-item flex flex-row px-2 py-1 items-center cursor-pointer max-w-full"
                        :class="{ 'tree-item-active': node.id === filterActiveId }"
                        @click="selectItem(node)"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <div class="inline-flex justify-center items-center w-4 p-1">
                            <v-icon
                                size="16"
                                v-if="node.has_child"
                                color="#70acfa"
                            >
                                {{
                                    node.is_open ? "fa fa-folder-open" : "far fa-folder"
                                }}
                            </v-icon>

                            <v-icon size="14" v-else color="#72a36c">
                                {{ node.id === filterActiveId ? "fas" : "far" }} fa-circle
                            </v-icon>
                        </div>
                        <div class="ml-2 word-wrap">{{ node.name }}</div>
                    </div>

                </template>
                <span class="text-white">{{ node.name }}</span>
            </v-tooltip>

            <div v-if="node.has_child">
                <div v-show="node.is_open" class="pl-2">
                    <FilterTreeView :items="node.children" :child="true"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getKeysWithChildren } from "@/utils/array";

export default {
    name: "FilterTreeView",

    props: {
        items: {
            type: Array,
            default: () => [],
        },

        child: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        node_list: [],
    }),

    created() {
        this.node_list = [ ...this.items ];
        this.initFolders();
    },

    computed: {
        ...mapGetters("base", ["filterActiveId"])
    },

    watch: {
        items: {
            handler() {
                const newKeys = getKeysWithChildren(this.items);
                const oldKeys = getKeysWithChildren(this.node_list);

                const diff = oldKeys.filter((t) => !newKeys.includes(t));

                if (!diff.length && newKeys.length === oldKeys.length) return;

                this.node_list = JSON.parse(JSON.stringify(this.items));
                this.initFolders();
            },
            deep: true,
        },

        filterActiveId: {
            handler() {
                // We dont want handle this shit in child node
                if (this.child) return;

                // What the fuck are you want to do with 0 group?
                if (this.filterActiveId === 0) return;

                // Trying to find in first layer of array
                let group = this.node_list.find(
                    (t) => t.id === this.filterActiveId
                );
                if (group) return;

                // We failed. Now lets find in children groups
                let parent_list = this.node_list.filter(
                    (t) => t.has_child
                );

                const scope = this;

                function findInParentNode(node_list) {
                    for (let node of node_list) {
                        let found = node.children.find(
                            (t) => t.id === scope.filterActiveId
                        );

                        if (found) {
                            // Если мы нашли ноду в папке, то прекращаем поиски
                            if (node.is_open !== undefined && node.is_open) break;
                            node.is_open = true;
                            return true;
                        }

                        // Если мы не нашли ноду, то нужно посмотреть, есть ли у текущей
                        // папки вложенные папки.
                        // Начинаем поиски в них рекурсивно.
                        if (node.has_child) {
                            found = findInParentNode(node.children);

                            if (found) {
                                if (node.is_open !== undefined && node.is_open)
                                    break;
                                node.is_open = true;
                                return true;
                            }
                        }
                    }
                    return false;
                }

                findInParentNode(parent_list);
            },
        },
    },

    methods: {
        initFolders() {
            this.node_list
                .filter((t) => t.has_child)
                .forEach((value) => {
                    this.$set(value, "is_open", false);
                });
        },

        selectItem(node) {
            if (node.has_child) {
                node.is_open = !node.is_open;
            }

            if (this.filterActiveId === node.id) return;

            this.$store.commit('base/FILTER_ACTIVE_SET', node.id);
        },

        shouldShowTooltip(item) {
            return item.name.length < 30;
        }
    },
};
</script>

<style scoped>
.word-wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.tree-item-active,
.tree-item:hover {
    background-color: #363637;
}
</style>
