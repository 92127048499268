import API, {APIReturn} from '@/api';
import {
    IEventParams,
    IEvent,
    IPeriod,
    IPeriodParams as BasePeriodParams, IEventUpdate, IGlobalState
} from './event.api';

const RESOURCE = "/auto";

// TODO: Inherit from event.api IEventParams
interface IAutoParams {
    begin_time: string,
    end_time: string,

    id_camera: number[],
    id_camera_zone: number[],
    id_event_type: number[],

    id_filter: number,

    offset: number,
    limit: number,
    load_info: boolean,

    recognize: boolean,
    search: string
}

interface IAuto {
    id: number,
    plate: string,
    model?: string
}

interface IAutoUpdate {
    plate: string,
    id_order: number[]
}

// :CopyPasta event.api.ts IEvents
interface IAutos {
    count: number,
    closed: boolean,
    data: IAuto[]
}

interface IPeriodParams extends BasePeriodParams {
    recognize: boolean
}

interface IEventAutoAttr {
    id: number,
    plate: string,
    id_orders: number[],
    change_plate: boolean
}

interface IEventAuto extends IEvent {
    auto: IEventAutoAttr
}

interface IEventAutos {
    count: number,
    data: IEventAuto
}

interface IEventAutoUpdate extends IEventUpdate {
    auto: IAutoUpdate
}

interface IEventArchiveParams extends IEventParams {
    recognize: boolean,
    search: string,
    flag_archive: boolean
}

interface IPlateSearchParam {
    plate: string
}

interface IPlateSearch {
    id: number,
    plate: string,
    last_seen: number,
    model: string
}

interface IEventChangedPlateParam {
    id_event: number
}

interface IEventChangedPlate {
    date: number,
    old: string,
    new: string
}

// TODO:
// * Отсутствует:
//      [DELETE] /v1/auto/{id_event}/events/
//      [GET] /v1/auto/{id_auto}/events/{id_event}/child/
//
export const AutoAPI = {
    autoGetAll: async (options: IAutoParams): APIReturn<IAutos> => {
        // [GET] /v1/auto/
        return await API.get<IAutos>(`${RESOURCE}/`, { params: options});
    },

    autoUpdate: async(id: number, data: IAutoUpdate): APIReturn<string> => {
        // [PUT] /v1/auto/:id/
        return await API.put<string>(`${RESOURCE}/${id}/`, data);
    },

    periodGet: async(options: IPeriodParams): APIReturn<IPeriod> => {
        // [GET] /v1/auto/period/
        return await API.get<IPeriod>(`${RESOURCE}/period/`, { params: options});
    },

    eventGetAll: async (id: number, options: IEventParams): APIReturn<IEventAutos> => {
        // [GET] /v1/auto/:id/events/
        return await API.get<IEventAutos>(`${RESOURCE}/${id}/events/`, { params: options});
    },

    eventUpdate: async(id: number, data: IEventAutoUpdate): APIReturn<IEventAuto> => {
        // [PUT] /v1/auto/:id/events/
        return await API.put<IEventAuto>(`${RESOURCE}/${id}/events/`, data);
    },

    eventGetArchiveAll: async(id: number, options: IEventArchiveParams): APIReturn<IEventAutos> => {
        return await API.get<IEventAutos>(`${RESOURCE}/${id}/events/archive/`, { params: options});
    },

    eventSetGlobalState: async(id_auto: number, options: IGlobalState): APIReturn<string> => {
        // [PUT] /v1/auto/:id/events/set_state/
        return await API.put<string>(`${RESOURCE}/${id_auto}/events/set_state/`, null, { params: options});
    },

    plateSearchOrder: async (options: IPlateSearchParam): APIReturn<Array<string>> => {
        // [GET] /v1/auto/plates/search/
        return await API.get<Array<string>>(`${RESOURCE}/plates/search/`, { params: options});
    },

    plateSearchDms: async(options: IPlateSearchParam): APIReturn<IPlateSearch> => {
        // [GET] /v1/auto/plates/search_dms/
        return await API.get(`${RESOURCE}/plates/search_dms/`, { params: options});
    },

    eventChangedPlate: async(options: IEventChangedPlateParam): APIReturn<IEventChangedPlate> => {
        return await API.get(`${RESOURCE}/event/plate_history/`, { params:options });
    },
};

interface IOrderParam {
    id_auto: number,
    id_event: number
}

interface IOrderItem {
    id: number,
    doc_num: string,
    doc_date: string,
    doc_issued: string,
    doc_closed: string,
    message: string,
    works: IOrderWork[]
}

interface IOrderWork {
    id: number,
    name: string,
    norma: number,
    carwash: boolean
}

interface IOrderHistoryWork {
    id: number,
    name: string,
    norma: number,
    carwash: boolean,
    update_time: string,
    action: string
}

interface IOrderHistory {
    id: number,
    doc_num: string,
    doc_date: string,
    doc_issued: string,
    doc_closed: string,
    message: string,
    works: IOrderHistoryWork[],
    update_time: string
}

interface ITrimAutoVideo {
    id: number,
    sid: string,
    filter_id: number,
    day: string
}

export const AutoOrderAPI = {
    ordersGetCurrent: async (id: number, options: IOrderParam): APIReturn<Array<IOrderItem>> => {
        // [GET] /v1/auto/:id/orders/current/
        return await API.get<Array<IOrderItem>>(`${RESOURCE}/${id}/orders/current/`, { params: options});
    },

    ordersGetArchive: async (id: number): APIReturn<Array<IOrderItem>> => {
        // [GET] /v1/auto/:id/orders/archive/
        return await API.get<Array<IOrderItem>>(`${RESOURCE}/${id}/orders/archive/`);
    },

    orderGetHistory: async(id: number, id_order: number): APIReturn<Array<IOrderHistory>> => {
        // [GET] /v1/auto/:id/orders/{id_order}/history/
        return await API.get<Array<IOrderHistory>>(`${RESOURCE}/${id}/orders/${id_order}/history/`);
    }
}

export const AutoTrimAPI = {
    trimAutoItem: async (data: ITrimAutoVideo): APIReturn<any> => {
        return await API.post(`${RESOURCE}/trim/`, data)
    }
}
