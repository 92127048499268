<template>
    <hr :class="classList" :style="styleList"/>
</template>

<script>
export default {
    name: "Divider",

    props: {
        vertical: {
            type: Boolean,
            default: false
        },

        color: {
            type: String,
            default: 'dark-gray'
        }
    },

    computed: {
        classList() {
            return [
                'divider',
                {
                    'divider-vertical': this.vertical,
                }
            ]
        },

        styleList() {
            return {
                borderColor: `var(--${this.color})`
            }
        }
    }
}
</script>

<style scoped>

.divider {
    display: block;

    flex: 1 1 0;

    border: solid;
    border-width: thin 0 0 0;

    height: 1px;
    max-height: 0;

    max-width: 100%;
}

.divider-vertical {
    align-self: stretch;

    border: solid;
    border-width: 0 thin 0 0;

    height: inherit;
    min-height: 100%;
    max-height: 100%;

    max-width: 1px;
    width: 1px;

    margin: 0 -1px /* Prevent wrapping in flex layouts */
}

</style>
