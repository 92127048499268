<template>
    <div class="notifications flex flex-col">
        <NotificationItem
            v-for="notification in notification_list"
            :key="notification.id"
            :data="notification"
            class="mb-1"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { generateId } from "@/utils/number.js";

import NotificationItem from "@/components/core/NotificationItem.vue";

export default {
    name: "Notifications",

    components: {
        NotificationItem,
    },

    data: () => ({
        notification_list: [],
    }),

    computed: {
        ...mapGetters("core", ["notificationQueue"]),

        notificationEmpty() {
            return this.notificationQueue.length <= 0;
        },
    },

    watch: {
        notificationEmpty(value) {
            if (value) return;

            while (this.notificationQueue.length > 0)
                this.addNotification(this.notificationQueue.shift());
        },
    },

    methods: {
        addNotification(value) {
            // TODO: validation for notification
            this.$nextTick(() => {
                this.$set(value, "id", generateId());
                this.notification_list.push(value);
            });
        },
    },
};
</script>

<style scoped>
.notifications {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 48px;
    margin-right: 4px;
}
</style>
