<template>
    <div class="no-selection">
        <v-date-picker
            ref="datePicker"
            v-model="currentDate"
            dark
            show-current
            show-adjacent-months
            flat
            width="260"
            no-title
            :locale="lng"
            first-day-of-week="1"
            @change="onDateChange"
            :picker-date.sync="pickerDate"
        >
        </v-date-picker>
    </div>
</template>

<script>
import { formatDate, timeFromISO } from "@/utils/time";
import { mapGetters } from "vuex";

export default {
    name: "DatePicker",

    props: {
        date: {
            type: [Object, String],
            required: true
        },

        month: {
            type: [Object, String],
            required: true
        },

        time_period: {
            type: Array,
            default: () => ([])
        }
    },

    data: () => ({
        currentDate: null, // Текущий выбранный день
        pickerDate: null,  // Текущий выбранный месяц

        picker_period_list: [],

        dontUseFirstTime: true, // @Temporary Убрать когда пойму как не вызывать watch в первый раз...
    }),

    computed: {
        ...mapGetters('base', ["lng"]),
        ...mapGetters('settings', ["filterSelectedId"])

    },

    watch: {
        date(value) {
            this.currentDate = formatDate(value);
        },

        time_period: {
            handler(value) {
                // TODO: :Cleanup По сути у нас теперь идёт проверка в store.setDataTimePeriod на то
                // что массивы одинаковые. И если они действительно одинаковые, то мы не мутируем переменную.
                // Возможно этот код тут теперь бесполезен, и можно проверку и сортировку не делать.
                //
                //  - n.kushnarenko 16.11.2021
                const newValues = JSON.parse(JSON.stringify(value));
                const oldValues = JSON.parse(JSON.stringify(this.picker_period_list));

                const diff =
                    JSON.stringify(newValues.sort()) !==
                    JSON.stringify(oldValues.sort());

                if (!diff) return;

                this.picker_period_list = JSON.parse(JSON.stringify(newValues));

                // Проверяем что текущий месяц соотносится с тем какой период
                // нам пришёл.
                // При обновлении данных, если выбран другой день, то период приходит
                // в диапазоне дня который у нас выбран. Чтобы избежать конфузии с
                // тем какие дни подсвечивать, нужно  проверить
                // что первая приходящая дата подходит под текущий месяц.
                // Если же она не подходит, обновляем текущий месяц в формате 2021-05
                if (this.picker_period_list.length) {
                    const first_date = timeFromISO(this.picker_period_list[0].date).toFormat('yyyy-LL');
                    if (this.pickerDate !== first_date) {
                        this.pickerDate = value;
                    }
                }
                this.$nextTick(() => {
                    this.setColors();
                })
            }
        },

        pickerDate(value) {
            if (this.dontUseFirstTime) {
                this.dontUseFirstTime = false;
                return;
            }
            this.onMonthChange(value)
        },

        filterSelectedId(value){
                // const newValues = JSON.parse(JSON.stringify(value));
                // const oldValues = JSON.parse(JSON.stringify(this.picker_period_list));

                // const diff =
                //     JSON.stringify(newValues.sort()) !==
                //     JSON.stringify(oldValues.sort());

                // if (!diff) return;
                //
                // this.picker_period_list = JSON.parse(JSON.stringify(newValues));

                // Проверяем что текущий месяц соотносится с тем какой период
                // нам пришёл.
                // При обновлении данных, если выбран другой день, то период приходит
                // в диапазоне дня который у нас выбран. Чтобы избежать конфузии с
                // тем какие дни подсвечивать, нужно  проверить
                // что первая приходящая дата подходит под текущий месяц.
                // Если же она не подходит, обновляем текущий месяц в формате 2021-05
                if (this.picker_period_list.length) {
                    const first_date = timeFromISO(this.picker_period_list[0].date).toFormat('yyyy-LL');
                    if (this.pickerDate !== first_date) {
                        this.pickerDate = value;
                    }
                }
                this.$nextTick(() => {
                    this.setColors();
                })
        }
    },

    methods: {
        onDateChange(value) {
            this.$emit('onDateChange', value);
        }
        ,

        onMonthChange(value) {
            this.$emit('onMonthChange', value)
        }
        ,

        setColors() {
            let table = this.$refs.datePicker.$refs.table.$el;
            let allDates = table.querySelectorAll(
                "table tbody .v-btn:not(.v-btn--disabled) .v-btn__content"
            );

            // NOTE: Не критичная оптимизация, но скорее всего нужно будет слегка
            // переделать функцию без цикла для того чтобы рендеру хрома было проще
            // пройти через все новые стили что мы ему даём.
            // Идея в том чтобы заранее создать структуру в которой
            // будут храниться все нужные нам переменные. После чего можно будет спокойно
            // отфильтровать значения по текущим значениям и присвоить только тем нодам что нам
            // требуется...
            //
            // -/ n.kushnarenko - 30.03.2021
            // let dates = this.picker_period_list.map((x) => parseInt(x.date.split("-")[2]));
            let className = "date-color";
            let classNameAll = "date-color-all";
            let classNameOnlyAllCam = "date-color-only-all-cam";
            allDates.forEach((date) => {
                // Проверяем значения в элементах.
                // Если данных находятся у нас в массиве нужных дат, то ставим
                // этому элементы css класс
                let day_in_time_picker = this.picker_period_list.find(
                    (element) => element.date.split("-")[2] == parseInt(date.innerHTML)
                )
                if (day_in_time_picker === undefined) {
                    return;
                }
                console.log(day_in_time_picker.state);
                date.classList.remove(classNameAll);
                date.classList.remove(classNameOnlyAllCam);
                date.classList.remove(className);
                switch (day_in_time_picker.state){
                    case "all_cam_done_with_event":
                        date.classList.add(classNameAll);
                        return;
                    case "all_cam_done_without_event":
                        date.classList.add(classNameOnlyAllCam);
                        return;
                    case "event_without_all_cam_done":
                        date.classList.add(className);
                        break;
                    default:
                        date.classList.remove(classNameAll);
                        date.classList.remove(classNameOnlyAllCam);
                        date.classList.remove(className);
                        break;
                }

                // Если элемент отсутствует в массиве дат, но имеет класс,
                // то удаляем ему этот класс.
                // if (date.classList.contains(className)) {
                //     date.classList.remove(className);
                // }
            });
        }
    }
};
</script>

<style>
.v-picker {
    /* Изменяем цвет графницы календаря */
    /* border-color: var(--dark-gray); */
    border: none;
}

.v-picker__body {
    /* Изменяем margin календаря чтобы он не был по центру */
    margin: 0 !important;
}

.v-date-picker-table {
    /* Изменяем высоту календаря */
    /* height: 228px; */
    height: 200px !important;
}

.theme--dark.v-picker__body {
    /* Изменяем цвет фона календаря */
    background-color: var(--item-background) !important;
}

.v-date-picker-table .v-btn--rounded {
    /* Изменяем скруглённость кнопок календаря */
    border-radius: 2px !important;
}

.v-date-picker-table .v-btn {
    /* Изменяем размер кнопок выбора даты */
    height: 28px !important;
    width: 28px !important;
}

.v-date-picker-table--date th {
    /* Изменяем отступ для текста хедера текущего дня */
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.v-date-picker-header .v-icon {
    /* Изменяем размер текста кнопок следующего и предыдущего месяца */
    font-size: 16px !important;
}

.v-date-picker-header .v-btn--icon {
    /* Изменяем размер кнопок следующего и предыдущего месяца */
    width: 30px !important;
    height: 30px !important;
}

.v-date-picker-table__current {
    /* Изменяем цвет выделения текущей даты */
    border-color: #6b7280 !important;
}

.v-date-picker-table .v-btn--active {
    /* Изменяем цвет выделения выбранной даты */
    background-color: #414444;
}

.v-date-picker-header__value button {
    /* Изменяем размер текста текущего месяца */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 0.9rem;
}

.date-color {
    /* Специальный класс для выделения дат в которых присутствуют данные которые нам нужны */
    color: #8bbdff !important;
    font-weight: bold;
}

.date-color-all {
    /* Специальный класс, для выделения дат в которых имеются события и выполнен AllCamDone */
    color: #FBFC03 !important;
    font-weight: bold;
}

.date-color-only-all-cam {
    /* Специальный класс, для выделения дат в которых выполнен AllCamDone, но нет событий */
    color: #00dd00 !important;
    font-weight: bold;
}

.v-btn--active .date-color {
    /* Специальный класс для выделения дат в которых присутствуют данные которые нам нужны */
    /* Работает специально для выделенного нам дня */
    color: #7adefd !important;
}
</style>
