import { 
    computePosition, 
    shift, 
    flip,
    offset
} from "@floating-ui/dom";
import { generateId } from "@/utils/number";

const PLACEMENTS = [
    'auto',
    'top',
    'bottom',
    'left',
    'right',
];


function computePlacement(modifiers) {
    const mods = Object.keys(modifiers);

    let result = 'auto'

    if (mods.length === 0) {
        console.warn(`mods placements is zero ${modifiers}`);
        return result;
    }

    for (let index = 0; index < mods.length; index++) {
        const placement_index = PLACEMENTS.indexOf(mods[index]);
        if (placement_index > -1) {
            result = PLACEMENTS[placement_index];
            break;
        }
    }

    return result;
}


class Tooltip {
    constructor(el, options = {}) {
        this.id = 0; 

        this.el = el;
        this.modifiers = options.modifiers;
        this.value = options.value;

        this.container = null;
        
        this.el.addEventListener('mouseenter', this.show.bind(this), false);
        this.el.addEventListener('mouseleave', this.hide.bind(this), false);
        this.el.addEventListener('focus', this.show.bind(this), false);
        this.el.addEventListener('blue', this.hide.bind(this), false);
    }

    update(options) {
        this.modifiers = options.modifiers;
        this.value = options.value;
    }

    destroy() {
        if (!this.el) return;

        this.el.removeEventListener('mouseenter', this.show.bind(this), false);
        this.el.removeEventListener('mouseleave', this.hide.bind(this), false);
        this.el.removeEventListener('focus', this.show.bind(this), false);
        this.el.removeEventListener('blue', this.hide.bind(this), false); 
    }

    show() {
        if (this.container) {
            return;
        }
        this.id = generateId() + '_tooltip';
        this.container = this.createElement();   

        const placement = computePlacement(this.modifiers);


        computePosition(this.el, this.container, {
            placement: placement,
            middleware: [
                shift({padding: 5}), 
                flip(),
                offset(5)
            ]
        }).then(({x, y}) => {
            Object.assign(this.container.style, {
               left: `${x}px`, 
               top: `${y}px`, 
            });
        }); 
    }

    hide() {  
        if (this.container && this.container.parentElement) {
            this.container.parentNode.removeChild(this.container); 
        }

        this.container = null;
    }

    createElement() {
        let container = document.createElement('div');
        container.id = this.id;

        container.style.left = '0px';
        container.style.top = '0px';
        container.className = 'custom-tooltip';

        let textElement = document.createElement('div');
        textElement.className = "custom-tooltip-text";
        textElement.innerHTML = this.value;

        container.appendChild(textElement);

        document.body.appendChild(container);

        container.style.display = "inline-block";

        return container;
    }

}

export default {
    bind(el, bindings) {
        el.tooltip = new Tooltip(el, bindings);
    },

    unbind(el) {
        if (!el.tooltip) {
            console.warn(`Tried to unbind tooltip, but \`el\` doen't have one ${el} : ${el.tooltip}`);
            return;
        }

        el.tooltip.destroy();
        el.tooltip = null;
    },

    update(el, bindings) {
        if (el.tooltip) {
            el.tooltip.update(bindings);
        }
    }
} 

