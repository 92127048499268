
import { ShiftAPI } from "@/api/shift.api";

const state = {
    is_data_loading: false,

    data_list: [],
    data_selected_id: 0,
    data_count: 0,

    shift_search: "",

    /** Чанки данных */
    chunk_limit: 25,
    chunk_offset: 0,
};

const getters = {
    isDataLoading: (state) => state.is_data_loading,
    dataList: (state) => state.data_list,

    dataSelectedId: (state) => state.data_selected_id,
    dataCount: (state) => state.data_count,

    shiftSearch: (state) => state.shift_search,

    chunkLimit: (state) => state.chunk_limit,
    chunkOffset: (state) => state.chunk_offset,
};

const actions = {
    async fetchDataList(context, { load_info }) {
        if (load_info && !context.getters.isAutoLoading) {
            context.commit("IS_DATA_LOADING", true);
        }

        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        options = {
            ...options,
            offset: context.getters.chunkOffset,
            limit: context.getters.chunkLimit,
            load_info: load_info,
            shift: context.getters.shiftSearch,
        };

        const response = await ShiftAPI.getAll(options);

        if (response.status !== 200) {
            console.log("fetchDataList: something went wrong", response);
            return;
        }

        const payload = response.data;

        if (load_info) {
            context.commit("DATA_LIST_SET", payload.data);
            context.commit("DATA_COUNT_SET", payload.count);

            context.commit("base/SET_IS_DAY_CLOSED", payload.closed, {
                root: true,
            });
            context.commit("IS_DATA_LOADING", false);
        } else {
            context.commit("DATA_LIST_APPEND", payload.data);
        }
    },

    async fetchDataPeriod(context) {
        const options = await context.dispatch("base/baseOptionsPeriod", null, {
            root: true,
        });

        const response = await ShiftAPI.getPeriod(options);
        if (response.status !== 200) {
            console.log("fetchDataPeriod: something went wrong", response);
            return;
        }

        await context.dispatch(
            "core/setDataTimePeriod",
            response.data.time_period,
            { root: true }
        );
    },

    async fetchEventList(context, { load_info, load_params }) {
        const currentId = context.getters.dataSelectedId;
        const {
            offset = context.rootGetters["event/eventChunkOffset"],
            limit = context.rootGetters["event/eventChunkLimit"],
            etalon = false, // флаг, что кнопка setEtalon была нажата;
        } = load_params || {};

        if (!currentId) {
            await context.dispatch("event/clearEventList", null, {
                root: true,
            });
            return;
        }

        if (load_info) {
            await context.dispatch("event/setEventLoading", true, {
                root: true,
            });
            await context.dispatch("event/setEventChunkOffset", offset || 0, {
                root: true,
            });
        }

        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        options = {
            ...options,
            offset: etalon ? 0 : offset,
            limit: etalon ? offset + limit : limit,
            load_info: load_info,
        };

        const response = await ShiftAPI.getEventList(currentId, options);
        if (response.status !== 200) {
            console.log("fetchEventList: something went wrong", response);
            return;
        }

        const payload = response.data;

        if (load_info) {
            await context.dispatch("event/setEventList", payload.data, {
                root: true,
            });
            await context.dispatch("event/setEventCount", payload.count, {
                root: true,
            });
            await context.dispatch("event/setEventLoading", false, {
                root: true,
            });
        } else {
            await context.dispatch("event/eventListAppend", payload.data, {
                root: true,
            });
        }
    },

    async skiEventUpdate(context, { id, id_event, data }) {
        await ShiftAPI.skiUpdateEvent(id, id_event, data).then((response) => {
            if (response.status === 403) {
                context.dispatch(
                    "core/addNotificationError",
                    {
                        header: "Закрытое событие",
                        message: "Вы не можете обновлять закрытые события.",
                        timeout: 3000,
                    },
                    { root: true }
                );
                return;
            }
            if (response.status !== 200) {
                context.dispatch(
                    "core/addNotificationError",
                    {
                        header: "Ошибка обновления события",
                        message:
                            "Произошла какая-то ошибка с обновлением события...",
                        timeout: 1250,
                    },
                    { root: true }
                );
            }
            context.commit(
                "event/EVENT_UPDATE",
                {
                    id_event: response.data.id,
                    data: response.data,
                },
                { root: true }
            );
        });
    },

    async eventSetGlobalState(context, id_state) {
        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        options = {
            ...options,
            id_state: id_state,
        };

        const response = await ShiftAPI.eventSetGlobalState(
            context.getters.dataSelectedId,
            options
        );

        if (response.status === 403) {
            await context.dispatch(
                "core/addNotificationError",
                {
                    header: "Закрытое событие",
                    message: "Вы не можете обновлять закрытые события.",
                    timeout: 3000,
                },
                { root: true }
            );
            return;
        }

        if (response.status !== 200) {
            await context.dispatch(
                "core/addNotificationError",
                {
                    header: "Ошибка обновления события",
                    message:
                        "Произошла какая-то ошибка с обновлением события...",
                    timeout: 2500,
                },
                { root: true }
            );
            return;
        }

        // Сбрасываем текущее событие, чтобы не было проблем с родительским
        await context.dispatch("event/clearEventParentId", null, {
            root: true,
        });
        await context.dispatch("event/setEventSelected", 0, { root: true });
        await context.dispatch("fetchEventList", { load_info: true });
    },

    async exportVideoEvent(context) {
        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        const event_ids = [
            context.rootGetters["event/eventSelectedId"],
            ...context.rootGetters["event/eventSubIds"],
        ];

        options = {
            ...options,
            event_ids: event_ids,
        };

        ShiftAPI.exportVideo(context.getters.dataSelectedId, options).then(
            (response) => {
                if (response.status === 404) {
                    context.dispatch(
                        "core/addNotificationError",
                        {
                            header: "Ошибка выгрузки видео",
                            message: "Файл не найден",
                            timeout: 2500,
                        },
                        { root: true }
                    );
                    return;
                }

                if (response.status !== 200) {
                    context.dispatch(
                        "core/addNotificationError",
                        {
                            header: "Ошибка выгрузки видео",
                            message: "",
                            timeout: 2500,
                        },
                        { root: true }
                    );
                    return;
                }

                const fileList = document.createElement("a");

                const currentData = context.getters.dataList.find(
                    (t) => t.id === context.getters.dataSelectedId
                );

                fileList.href = URL.createObjectURL(new Blob([response.data]));
                fileList.setAttribute("download", `${currentData.skipass}.zip`);
                fileList.setAttribute(
                    "type",
                    "application/zip, application/x-zip-compressed, multipart/x-zip"
                );

                fileList.click();
            }
        );
    },
};

const mutations = {
    IS_DATA_LOADING(state, value) {
        state.is_data_loading = value;
    },

    DATA_LIST_SET(state, payload) {
        state.data_list = [...payload];

        if (state.data_list.length > 0) {
            state.data_selected_id = state.data_list[0].id;
        } else {
            state.data_selected_id = 0;
        }
    },

    DATA_LIST_APPEND(state, payload) {
        state.data_list = [...state.data_list, ...payload];
    },

    DATA_LIST_CLEAR(state) {
        state.data_list = [];
    },

    DATA_COUNT_SET(state, value) {
        state.data_count = value;
    },

    DATA_SELECTED_SET(state, value) {
        state.data_selected_id = value;
    },

    CHUNK_OFFSET_SET(state, value) {
        state.chunk_offset = value;
    },

    SEARCH_SET(state, value) {
        state.shift_search = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
