<template>
    <div
        class="flex flex-row items-center justify-center font-bold text-xs bg-blue-900"
        v-show="isUpdate"
    >
    
        <div>Доступно новое обновление!</div>

        <div
            class="ml-2 cursor-pointer font-bold update-button my-0.5 hover:bg-blue-800 px-2"
            @click="update"
        >
            Обновить
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Update",

    computed: {
        ...mapGetters('core', ["isUpdate"])
    },

    methods: {
        update() {
            window.location.reload();
        }
    },
};
</script>

<style scoped>
.update-button {
    border: 1px solid #bfdbfe;
}
</style>