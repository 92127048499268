import { reactive } from "vue";
import { io } from "socket.io-client";
import {saveSidLocal} from "@/storage/token.storage";

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: []
});

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === "production" ? undefined : "http://192.168.10.219:8000";

export const socket = io(URL, { transports: ['websocket', 'polling', 'flashsocket'] });

// setTimeout( () => {
//     socket.emit('submit_notification', );
//     console.log("TRY SUBMIT");
// }, 20);

socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
  setTimeout(() => {
    socket.connect();
    saveSidLocal(socket.id);
  }, 150);
});


