import Vue from 'vue'
import Vuex from 'vuex'

import auth from "@/store/auth.store";
import auto from "@/store/auto.store";
import base from "@/store/base.store";
import core from "@/store/core.store";
import event from "@/store/event.store";
import kko from "@/store/kko.store";
import people from "@/store/people.store";
import video from "@/store/video.store";
import ski from "@/store/ski.store";
import shift from "@/store/shift.store";
import ozon from "@/store/ozon.store";
import stoma from "@/store/stoma.store";
import settings from "@/store/settings.store";
import settings_role from "@/store/settings/roles.store";
import settings_user from "@/store/settings/user.store";
import settings_camera from "@/store/settings/camera.store";
import settings_scenario from "@/store/settings/scenario.store";

Vue.use(Vuex)
export default new Vuex.Store({
	// strict: true, //TODO: production disable
	modules: {
		auth: auth,
		auto: auto,
		base: base,
		core: core,
		event: event,
		kko: kko,
		people: people,
		video: video,
    ski: ski,
    shift: shift,
    settings: settings,
    settings_role: settings_role,
    settings_user: settings_user,
    settings_camera: settings_camera,
    settings_scenario: settings_scenario,
    stoma: stoma,
    ozon: ozon
	}
})
