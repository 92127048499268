//
// Полезные хелперы для работы с числами
//

/**
 * Возвращение числа в рамках чисел min/max
 *
 * @param value
 * @param min
 * @param max
 * @returns {*}
 */
export function clamp(value, min ,max) {
    value = value <= max ? value : max;
    value = value >= min ? value : min;
    return value;
}


export const generateId = function () {
    return '_' + Math.random().toString(36).substr(2, 9);
};
