import { AutoAPI, AutoOrderAPI } from "@/api/auto.api";

const state = {
    // Загружаеются ли события автомобилей
    is_auto_loading: false,

    // Массив автомобилей
    auto_list: [],

    // ID текущего выбранного события
    auto_selected_id: 0,

    /** Количество машин */
    auto_count: 0,

    // Массив данных ДМС для плагина заказчики
    auto_orders: [],

    // Ордера текущего события
    orders_in_event: [],

    order_selected_id: 0, // Выбранный ордер

    order_modal_visible: false,

    /** Чанки данных */
    auto_chunk_limit: 25,
    auto_chunk_offset: 0,

    /** Поле для поиска */
    auto_search_text: "",

    // Флаг нужно ли выгружать архивные события или события в рамках дня
    // true - события архива, false - события текущего дня
    is_archive_orders: false,

    // Флаг который показывает должен ли автомобиль быть распознаным или нет,
    // также может быть null тогда будут показаны все автомобили.
    is_auto_recognize: null,

    // Флаг указывает нужно ли нам выгружать историю автомобилей
    is_auto_history: false,
}

const getters = {
    isAutoLoading: state => state.is_auto_loading,

    autoList: state => state.auto_list,
    autoSelectedId: state => state.auto_selected_id,

    autoCount: state => state.auto_count,

    autoOrders: state => state.auto_orders,
    ordersInEvent: state => state.orders_in_event,

    orderSelectedId: state => state.order_selected_id,
    orderModalVisible: state => state.order_modal_visible,

    autoChunkLimit: state => state.auto_chunk_limit,
    autoChunkOffset: state => state.auto_chunk_offset,

    autoSearchText: state => state.auto_search_text,

    isArchiveOrders: state => state.is_archive_orders,

    isAutoRecognize: state => state.is_auto_recognize,
    isAutoHistory: state => state.is_auto_history,
};

const actions = {
    async fetchAutoList(context, { load_info }) {
        if (load_info && !context.getters.isAutoLoading) {
            context.commit('SET_AUTO_LOADING', true);
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            offset: context.getters.autoChunkOffset,
            limit: context.getters.autoChunkLimit,
            load_info: load_info,
            search: context.getters.autoSearchText
        }

        // Если у нас присутствует флаг isAutoRecognize
        // то добавляем его в запрос
        if (context.getters.isAutoRecognize !== null) {
            options = {
                ...options,
                recognize: context.getters.isAutoRecognize,
            };
        }

        await AutoAPI.autoGetAll(options).then((response) => {
            const payload = response.data;

            if (load_info) {
                context.commit('SET_AUTO_LIST', payload.data);
                context.commit("SET_AUTO_COUNT", payload.count);
                context.commit('base/SET_IS_DAY_CLOSED', payload.closed,  { root :true});

                context.commit('SET_AUTO_LOADING', false);
            }
            else {
                context.commit('AUTO_LIST_APPEND', payload.data);
            }

        });
    },

    async fetchAutoPeriod(context) {
        const options = await context.dispatch('base/baseOptionsPeriod', null, { root: true });

        await AutoAPI.periodGet(options).then((response) => {
            context.dispatch(
                "core/setDataTimePeriod",
                response.data.time_period,
                { root: true }
            );
        });
    },

    async fetchEventList(context, { load_info }) {
        const currentAutoId = context.getters.autoSelectedId;

        if (!currentAutoId) {
            await context.dispatch('event/clearEventList', null, { root: true });
            return;
        }

        if (load_info) {
            await context.dispatch('event/setEventLoading', true, { root :true});
            await context.dispatch('event/setEventChunkOffset', 0, { root :true});
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            offset: context.rootGetters['event/eventChunkOffset'],
            limit: context.rootGetters['event/eventChunkLimit'],
            load_info: load_info
        }

        await AutoAPI.eventGetAll(currentAutoId, options).then(response => {
            let payload = response.data;

            if (load_info) {
                context.dispatch('event/setEventList', payload.data, { root: true });
                context.dispatch('event/setEventCount', payload.count, { root: true });
                context.dispatch('event/setEventLoading', false, { root :true});
            }
            else {
                context.dispatch('event/eventListAppend', payload.data, { root: true });
            }
        });
    },

    async fetchEventListArchive(context, { load_info }) {
        const currentAutoId = context.getters.autoSelectedId;

        if (!currentAutoId) {
            await context.dispatch('event/clearEventList', null, { root: true });
            return;
        }

        if (load_info) {
            await context.dispatch('event/setEventLoading', true, { root :true});
            await context.dispatch('event/setEventChunkOffset', 0, { root :true});
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            flag_archive: context.getters.isArchiveOrders,
            offset: context.rootGetters['event/eventChunkOffset'],
            limit: context.rootGetters['event/eventChunkLimit'],
            load_info: load_info
        }

        await AutoAPI.eventGetArchiveAll(currentAutoId, options).then(response => {
            let payload = response.data;

            if (load_info) {
                context.dispatch('event/setEventList', payload.data, { root: true });
                context.dispatch('event/setEventCount', payload.count, { root: true });
                context.dispatch('event/setEventLoading', false, { root :true});
            }
            else {
                context.dispatch('event/eventListAppend', payload.data, { root: true });
            }
        });
    },

    async autoUpdate(context, { id_auto, data}) {
        const response = await AutoAPI.autoUpdate(id_auto, data);

        if (response.status !== 200) {
            context.dispatch("core/addNotificationError", {
                header: "Ошибка обновления автомобиля",
                message:
                    "По каким-то причинам не получилось обновить автомобиль...",
                timeout: 1250,
            }, { root: true });
            return;
        }

        context.commit("AUTO_UPDATE_WITH_CHANGE_NUMBER", {
            id_old: id_auto,
            data: response.data
        });
    },

    clearAutoList(context) {
        context.commit("CLEAR_AUTO_LIST");
    },

    setAutoSelected(context, value) {
        context.commit("SET_AUTO_SELECTED", value);
    },

    // Запрос к бекенду на обновление события
    async eventUpdate(context, { id_event, data }) {
        await AutoAPI.eventUpdate(id_event, data).then(
            (response) => {
                if (response.status === 403) {
                    context.dispatch("core/addNotificationError", {
                        header: "Закрытое событие",
                        message:
                            "Вы не можете обновлять закрытые события.",
                        timeout: 3000,
                    }, { root: true });
                    return;
                }

                if (response.status !== 200) {
                    context.dispatch("core/addNotificationError", {
                        header: "Ошибка обновления события",
                        message:
                            "Произошла какая-то ошибка с обновлением события...",
                        timeout: 1250,
                    }, { root: true });
                }

                context.commit("event/EVENT_UPDATE", {
                    id_event: response.data.id,
                    data: response.data
                }, { root: true});
            }
        );
    },

    async fetchAutoOrders(context) {
        const id_auto = context.getters.autoSelectedId;

        // Если требуются архивные данные
        if (context.state.is_archive_orders) {
            await AutoOrderAPI.ordersGetArchive(id_auto).then(response => {
                context.commit('SET_AUTO_ORDERS', response.data);
            });
        }
        else {
            // TODO: Возможно не работает когда мы забираем данные, когда ищем машину в ордерах
            const id_event = context.rootGetters['event/eventSelectedId'];
            const params = {
                id_event: id_event
            }

            // Если требуются данные за текущий день
            await AutoOrderAPI.ordersGetCurrent(id_auto, params).then(response => {
                context.commit('SET_AUTO_ORDERS', response.data);
            });
        }
    },

    // TODO: Deprecated
    clearAutoOrders(context) {
        console.warn("clearAutoOrders deprecated. Use commit instead");
        context.commit("CLEAR_AUTO_ORDERS");
    },

    // TODO: Deprecated
    setIsArchiveOrders(context, value) {
        console.warn("setIsArchiveOrders deprecated. Use commit instead");
        context.commit("SET_IS_ARCHIVE_ORDERS", value);
    },

    setIsAutoRecognize(context, value) {
        context.commit("SET_IS_AUTO_RECOGNIZE", value);
    },

    setIsAutoHistory(context, value) {
        context.commit("SET_IS_AUTO_HISTORY", value);
    },

    setAutoCount(context, value) {
        context.commit('SET_AUTO_COUNT', value);
    },

    setAutoChunkOffset(context, value) {
        context.commit('SET_AUTO_CHUNK_OFFSET', value);
    },

    setAutoSearch(context, value) {
        context.commit('SET_AUTO_SEARCH', value);
    },

    async eventSetGlobalState(context, id_state) {
        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            id_state: id_state
        }

        const response = await AutoAPI.eventSetGlobalState(
            context.getters.autoSelectedId,
            options
        );

        if (response.status === 403) {
            await context.dispatch("core/addNotificationError", {
                    header: "Закрытое событие",
                    message:
                        "Вы не можете обновлять закрытые события.",
                    timeout: 3000,
                }, { root: true });
            return;
        }

        if (response.status !== 200) {
            await context.dispatch("core/addNotificationError", {
                header: "Ошибка обновления события",
                message:
                    "Произошла какая-то ошибка с обновлением события...",
                timeout: 2500,
            }, { root: true });
            return;
        }

        // Сбрасываем текущее событие, чтобы не было проблем с родительским
        await context.dispatch("event/clearEventParentId", null, { root: true});
        await context.dispatch('event/setEventSelected', 0, { root: true});
        await context.dispatch('fetchEventList', { load_info: true });
    },

    setAutoLoading(context, value) {
        context.commit('SET_AUTO_LOADING', value);
    },
};

const mutations = {
    SET_AUTO_LOADING(state, value) {
        state.is_auto_loading = value;
    },

    SET_AUTO_LIST(state, payload) {
        state.auto_list = [...payload];

        if (state.auto_list.length > 0) {
            state.auto_selected_id = state.auto_list[0].id;
        } else {
            state.auto_selected_id = 0;
        }
    },

    AUTO_LIST_APPEND(state, payload) {
        state.auto_list = [
            ...state.auto_list,
            ...payload
        ]
    },

    CLEAR_AUTO_LIST(state) {
        state.auto_list = [];
    },

    SET_AUTO_SELECTED(state, value) {
        state.auto_selected_id = value;
    },

    SET_AUTO_ORDERS(state, payload) {
        state.auto_orders = [...payload];
    },

    CLEAR_AUTO_ORDERS(state) {
        state.auto_orders = [];
    },

    SET_IS_ARCHIVE_ORDERS(state, value) {
        state.is_archive_orders = value;
    },

    SET_IS_AUTO_RECOGNIZE(state, value) {
        state.is_auto_recognize = value;
    },

    SET_IS_AUTO_HISTORY(state, value) {
        state.is_auto_history = value;
    },

    SET_AUTO_COUNT(state, value) {
        state.auto_count = value;
    },

    SET_AUTO_CHUNK_OFFSET(state, value) {
        state.auto_chunk_offset = value;
    },

    SET_AUTO_SEARCH(state, value) {
        state.auto_search_text = value;
    },

    ORDERS_IN_EVENT_SET(state, value) {
        state.orders_in_event = [...value];
    },

    ORDER_SELECTED_ID_SET(state, value) {
        state.order_selected_id = value;
    },

    ORDER_MODAL_VISIBLE(state, value) {
        state.order_modal_visible = value;
    },

    AUTO_UPDATE_WITH_CHANGE_NUMBER(state, { id_old, data }) {
        const new_auto_exists = state.auto_list.find(t => t.id === data.id);

        // if new car exists in current list
        if (new_auto_exists) {
            // remove old car from the list
            state.auto_list.splice(state.auto_list.findIndex(t => t.id === id_old), 1);

            // select new car
            state.auto_selected_id = data.id;
            return;
        }

        const auto = state.auto_list.find(t => t.id === id_old);
        if (!auto) return;

        Object.entries(data).forEach(([key, value]) => {
            if (auto[key] !== value) {
                auto[key] = value;
            }
        });

        state.auto_selected_id = data.id;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
