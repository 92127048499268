import UserAPI from "@/api/user.api.ts";

const state = {
    role_list: [],
    rule_list: [],
    rule_list_grouped: [],
    group_list: []
}

const getters = {
    roleList: state => state.role_list,
    ruleList: state => state.rule_list,
    groupList: state => state.group_list,
    ruleListGrouped: state => state.rule_list_grouped,
};

const actions = {
    async fetchRoleList(context) {
        const response = await UserAPI.rolesGetAll();
        // TODO: Error handler

        context.commit('ROLE_LIST_SET', response.data);
    },

    async fetchRuleList(context) {
        const rule_response = await UserAPI.rulesGetAll();
        context.commit('RULE_LIST_SET', rule_response.data);

        {
            const group_response = await UserAPI.ruleGroupsGetAll();
            const group_list = group_response.data;

            context.commit('GROUP_LIST_SET', group_list);

            let temp_rule_list = [];

            group_list.forEach(value => {
                 temp_rule_list.push({
                        ...value,
                        rules: rule_response.data.filter(t => t.id_rule_group === value.id).sort((a, b) => a.id - b.id)
                 });
            });

            context.commit('RULE_LIST_GROUPED_SET', temp_rule_list);
        }
    },

    roleAppend(context, payload) {
        context.commit("ROLE_APPEND", payload);
    },

    roleUpdate(context, {id_role, data}) {
        context.commit('ROLE_UPDATE', {id_role, data});
    },

    roleDelete(context, id_role) {
        context.commit('ROLE_DELETE', id_role);
    },

    // TODO: :DeleteAfterModuleRegister Временное решение пока не появится регистрацией модулей которое описывается
    // в файле SettingsRoles.vue
    clearData(context) {
        context.commit('CLEAR_DATA');
    }
};

const mutations = {
    ROLE_LIST_SET(state, payload) {
        state.role_list = [...payload];
    },

    RULE_LIST_SET(state, payload) {
        state.rule_list = [...payload];
    },

    ROLE_APPEND(state, payload) {
        state.role_list.push({...payload});
    },

    ROLE_UPDATE(state, {id_role, data}) {
        let item = state.role_list.find(t => t.id === id_role);

        if (!item) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'id') return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },

    ROLE_DELETE(state, id_role) {
        const index = state.role_list.findIndex(t => t.id === id_role);

        state.role_list.splice(index, 1);
    },

    GROUP_LIST_SET(state, payload) {
        state.group_list = [...payload];
    },

    RULE_LIST_GROUPED_SET(state, payload) {
        state.rule_list_grouped = [...payload];
    },

    // :DeleteAfterModuleRegister
    CLEAR_DATA(state) {
        state.role_list = [];
        state.rule_list = [];
        state.group_list = [];
        state.rule_list_grouped = [];
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
