import { DateTime } from 'luxon';

// TODO:
//  * Дополнительные хелперы добавления секунд/часов/итд. В текущий момент
// присутствуют только минуты

const DEFAULT_TZ = 'UTC';

/** Преобразование переменной строки ISO в объект luxon datetime */
export const timeFromISO = function(value, ts = DEFAULT_TZ) {
    if (typeof value !== 'string') {
        throw  Error(`timeFromISO: Value is not a String. Current type is: ${typeof value}`);
    }
    return DateTime.fromISO(value, { zone: ts});
}

/** Преобразование переменной в секундах в объект luxon datetime */
export const timeFromSeconds = function(value, tz = DEFAULT_TZ) {
    if (typeof value !== 'number') {
        throw  Error(`timeFromSeconds: Value is not a Number. Current type is: ${typeof value}`);
    }
    return DateTime.fromSeconds(value).setZone(tz);
}

/** Преобразование любой переменной в объект luxon datetime */
export const timeFromValue = function(value, tz = DEFAULT_TZ) {
    if (value instanceof DateTime) {
        return value;
    }

    if (typeof value === 'string') {
        return timeFromISO(value, tz);
    }

    if (typeof value === 'number') {
        return timeFromSeconds(value, tz);
    }

    throw Error(`timeFromValue: Unknown value type for: ${value} , type: ${typeof value}`);
}

/** Форматирование времени в формате date-time */
export const formatDateTime = function(value, format = 'yyyy-LL-dd HH:mm:ss', tz = DEFAULT_TZ) {
    return timeFromValue(value, tz).toFormat(format);
}

/** Форматирование времени в формате date */
export const formatDate = function(value, tz = DEFAULT_TZ) {
    return timeFromValue(value, tz).toFormat('yyyy-LL-dd');
}

/** Форматирование времени в формате time */
export const formatTime = function(value, tz = DEFAULT_TZ) {
    return timeFromValue(value, tz).toFormat('HH:mm:ss');
}

/** Преобразование времени в секунды */
export const toSeconds = function(value, tz = DEFAULT_TZ) {
    return timeFromValue(value, tz).toSeconds();
}

/** Добавление ко времени минут */
export const addMinutes = function(value, minutes, tz = DEFAULT_TZ) {
    return timeFromValue(value, tz).plus({minutes: minutes})
}

/** Разница между двумя временами в `минутах` или других форматах указаных в параметрах  */
export const diffTime = function(first, second, diff = 'minutes') {
    let firstDatetime = timeFromValue(first);
    let secondDateTime = timeFromValue(second);

    if (!firstDatetime || !secondDateTime) {
        throw Error(`diffTime: Some of datetime is null. firstDateTime ${firstDatetime} , secondDateTime: ${secondDateTime}`);
    }

    return firstDatetime.diff(secondDateTime, diff);
}

/** Добавляем к строке 0 если строка длинной в один символ. */
export const pad = function(value) {
    let s = String(value);
    if (s.length < 2) s = "0" + s;
    return s;
}

/** Быстрый перевод unix времени в строковый формат */
export const fastTimeFormat = function(value) {
    const h = pad(~~(value / 3600));
    const m = pad(~~((value % 3600) / 60));
    const s = pad(value % 60);
    return `${h}:${m}:${s}`;
}
