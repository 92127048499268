/*
    Mixin нотификация для более удобного использований нотификаций
    
*/
export default {
    methods: {
        showInfoNotification(header, message, timeout = 5000) {
            this.showNotification(header, message, timeout, "info");
        },

        showErrorNotification(header, message, timeout = 5000) {
            this.showNotification(header, message, timeout, "error");
        },

        showNotification(header, message, timeout = 5000, type) {
            this.$store.dispatch("core/addNotification", {
                header, message, timeout, type
            });
        }
    }
}