import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css';
import 'video.js/dist/video-js.css';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import '@/assets/css/main.css';

import ErrorService from '@/error.js'
import "./filters";

if (process.env.NODE_ENV === 'production') {
    const errorHandler = new ErrorService(Vue);  // eslint-disable-line no-unused-vars

    Vue.config.errorHandler = (err, vm, info) => errorHandler.onVueError(err, vm, info);
}

Vue.config.productionTip = false

// register components
Vue.component("LoadingSpinner", () => import("./components/core/LoadingSpinner.vue"));

Vue.component("VueSelect", vSelect); 

Vue.prototype.$can = function(rule_name: String) {
    if (!this.$store.getters['auth/isLogIn']) return false;

    if (this.$store.getters['auth/userInfo'].id === undefined)
    {
        this.$store.dispatch('auth/checkLogIn');
    }

    const current_user = this.$store.getters['auth/userInfo'];

    // console.log("Current user", current_user);

    if (current_user.is_super_user) {
        return true;
    }

    // console.log(current_user.list_access)
    if (!current_user.list_access) {
        // console.log("List access is empty", current_user.list_access);
        return false;
    }

    // console.log("User rule set", current_user.list_access);
    return current_user.list_access.includes(rule_name)
}

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app');
