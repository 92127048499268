import { PeopleAPI, PeopleAttrAPI } from "@/api/people.api";

const state = {
    // Загружаеются ли события людей
    is_people_loading: false,

    /** Массив людей */
    people_list: [],

    /** ID текущего выбранного человека */
    people_selected_id: 0,

    /** Люди выбранные для схлопывания */
    // TODO: rename to people_sub_ids
    people_links_ids: [],

    /** Количество людей */
    people_count: 0,

    /** Чанки данных */
    people_chunk_limit: 25,
    people_chunk_offset: 0,

    card_view: {
        show: false
    },

    card_employee_view: {
        show: false
    },

    /** Список возрастов */
    age_list: [],

    /** Список гендеров */
    gender_list: [],

    /** Список департаментов */
    department_list: [],

    /** Список категорий */
    category_list: [],
    category_selected_list: [],
}

const getters = {
    isPeopleLoading: state => state.is_people_loading,

    peopleList: state => state.people_list,
    peopleSelectedId: state => state.people_selected_id,
    peopleLinksIds: state => state.people_links_ids,

    peopleCount: state => state.people_count,

    peopleChunkLimit: state => state.people_chunk_limit,
    peopleChunkOffset: state => state.people_chunk_offset,

    cardView: state => state.card_view,
    cardEmployeeView: state => state.card_employee_view,

    ageList: state => state.age_list,
    genderList: state => state.gender_list,
    departmentList: state => state.department_list,

    categoryList: state => state.category_list,
    categorySelectedList: state => state.category_selected_list,
};

const actions = {
    async fetchPeopleList(context, { load_info }) {
        if (load_info && !context.getters.isPeopleLoading) {
            context.commit('SET_PEOPLE_LOADING', true);
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            id_category: context.getters.categorySelectedList,
            offset: context.getters.peopleChunkOffset,
            limit: context.getters.peopleChunkLimit,
            load_info: load_info
        }

        await PeopleAPI.peopleGetAll(options).then((response) => {
            let payload = response.data;

            if (load_info) {
                context.commit('SET_PEOPLE_LIST', payload.data);
                context.commit("SET_PEOPLE_COUNT", payload.count);
                context.commit('base/SET_IS_DAY_CLOSED', payload.closed, { root: true });
                context.commit('SET_PEOPLE_LOADING', false);
            }
            else {
                context.commit('PEOPLE_LIST_APPEND', payload.data);
            }
        });
    },

    // TODO: rename to getPeopleList
    async returnPeopleList(context) {
        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            id_category: context.getters.categorySelectedList,
            offset: context.getters.peopleChunkOffset,
            limit: context.getters.peopleChunkLimit,
            load_info: true
        }

        const response = await PeopleAPI.peopleGetAll(options);

        const payload = response.data;

        return [payload.data, payload.count];
    },

    async fetchPeoplePeriod(context) {
        let options = await context.dispatch('base/baseOptionsPeriod', null, { root: true });

        options = {
            ...options,
            id_category: context.getters.categorySelectedList,
        }

        await PeopleAPI.periodGet(options).then((response) => {
            context.dispatch(
                "core/setDataTimePeriod",
                response.data.time_period,
                { root: true }
            );
        });
    },

    peopleUpdateApply(context, { id_people, data }) {
        context.commit("PEOPLE_UPDATE", {
            id_people: id_people,
            data: data
        });
    },

    clearPeopleList(context) {
        context.commit("CLEAR_PEOPLE_LIST");
    },

    setPeopleSelected(context, value) {
        context.commit("SET_PEOPLE_SELECTED", value);
    },

    peopleLinksIdsAppend(context, value) {
        context.commit('PEOPLE_LINKS_IDS_APPEND', value);
    },

    peopleLinksIdsRemove(context, value) {
        context.commit('PEOPLE_LINKS_IDS_REMOVE', value);
    },

    peopleLinksIdsClear(context) {
        context.commit('PEOPLE_LINKS_IDS_CLEAR');
    },

    async peopleUpdate(context, { id_people, data }) {
        await PeopleAPI.peopleUpdate(id_people, data).then(
            (response) => {
                if (response.status === 403) {
                    context.dispatch("core/addNotificationError", {
                        header: "Не получилось изменить персонажа",
                        message:
                            "По какой-то причине не удалось изменить персонажа.",
                        timeout: 3000,
                    }, { root: true });
                    return;
                }

                if (response.status !== 200) {
                    context.dispatch("core/addNotificationError", {
                        header: "Ошибка обновления события",
                        message:
                            "Произошла какая-то ошибка с обновлением события...",
                        timeout: 1250,
                    }, { root: true });
                }

                context.commit("PEOPLE_UPDATE", {
                    id_people: response.data.id,
                    data: response.data
                });
            }
        );
    },

    async updatePeopleList(context) {
        await context.dispatch('event/eventSubClear', null, { root: true});
        await context.dispatch('peopleLinksIdsClear');

        // Временно изменяем текущие значения offset/limit чтобы мы могли
        // выгрузить все текущие данные которые у нас есть.
        const old_offset = context.getters.peopleChunkOffset;
        const old_limit = context.getters.peopleChunkLimit;

        await context.dispatch("setPeopleChunkOffset", 0);
        await context.dispatch("setPeopleChunkLimit", context.getters.peopleList.length);

        const [data, count] = await context.dispatch('returnPeopleList');

        // Возвращаем старые значения для offset/limit
        await context.dispatch("setPeopleChunkOffset", old_offset);
        await context.dispatch("setPeopleChunkLimit", old_limit);

        // Работаем с данными
        await context.dispatch("setPeopleCount", count);

        // Убираем персонажей и добавляем новых
        const old_ids = context.getters.peopleList.map(t => t.id);
        const new_ids = data.map(t => t.id);

        const ids_for_delete = old_ids.filter(t => !new_ids.includes(t));
        const ids_for_append = new_ids.filter(t => !old_ids.includes(t));

        const items_for_append = data.filter(t => ids_for_append.includes(t.id));

        context.commit("PEOPLE_LIST_DELETE_ITEMS", ids_for_delete);
        context.commit("PEOPLE_LIST_APPEND", items_for_append);

        await context.dispatch('event/setEventChunkOffset', 0, { root: true});
        await context.dispatch('fetchEventList', { load_info: true });
    },


    async updateLinksPeople(context) {
        if (!context.getters.peopleLinksIds.length) {
            await context.dispatch('core/addNotificationInfo', {
                header: "Невозможно объединить персонажей",
                message: "Необходимо выбрать как минимум 2-х персонажей для объединения.\n",
                timeout: 2000
            }, { root: true })
            return;
        }

        if (!context.getters.peopleSelectedId) {
            await context.dispatch('core/addNotificationInfo', {
                header: "Невозможно объединить персонажей",
                message: "Не выбран главный персонаж",
                timeout: 2000
            },
                { root: true })

            return;
        }

        const peoples_to_union = [
            ...context.getters.peopleLinksIds,
            context.getters.peopleSelectedId
        ]

        const response = await PeopleAPI.unionPeople(peoples_to_union);

        if (response.status === 403) {
            await context.dispatch('core/addNotificationError', {
                header: "Не удалось объединить персонажей",
                message: "Что-то пошло не так и нам не удалось объединение персонажей...",
                timeout: 2000
            },
                { root: true })
            return;
        }

        if (response.status === 200) {
            await context.dispatch('peopleLinksIdsClear');

            // Временно изменяем текущие значения offset/limit чтобы мы могли
            // выгрузить все текущие данные которые у нас есть.
            const old_offset = context.getters.peopleChunkOffset;
            const old_limit = context.getters.peopleChunkLimit;

            await context.dispatch("setPeopleChunkOffset", 0);
            await context.dispatch("setPeopleChunkLimit", context.getters.peopleList.length);

            const [data, count] = await context.dispatch('returnPeopleList');

            // Возвращаем старые значения для offset/limit
            await context.dispatch("setPeopleChunkOffset", old_offset);
            await context.dispatch("setPeopleChunkLimit", old_limit);

            // Работаем с данными
            await context.dispatch("setPeopleCount", count);

            // Убираем персонажей и добавляем новых
            const old_ids = context.getters.peopleList.map(t => t.id);
            const new_ids = data.map(t => t.id);

            const ids_for_delete = old_ids.filter(t => !new_ids.includes(t));
            const ids_for_append = new_ids.filter(t => !old_ids.includes(t));

            const items_for_append = data.filter(t => ids_for_append.includes(t.id));

            context.commit("PEOPLE_LIST_DELETE_ITEMS", ids_for_delete);
            context.commit("PEOPLE_LIST_APPEND", items_for_append);

            await context.dispatch('event/setEventChunkOffset', 0, { root: true});
            await context.dispatch('fetchEventList', { load_info: true });
        }
    },

    async fetchEventList(context, { load_info }) {
        const currentPersonId = context.getters.peopleSelectedId;

        if (!currentPersonId) {
            await context.dispatch('event/clearEventList', null, { root: true });
            return;
        }

        if (load_info) {
            await context.dispatch('event/setEventLoading', true, { root: true });
            await context.dispatch('event/setEventChunkOffset', 0, { root: true });
        }

        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            offset: context.rootGetters['event/eventChunkOffset'],
            limit: context.rootGetters['event/eventChunkLimit'],
            load_info: load_info
        }

        await PeopleAPI.eventGetAll(currentPersonId, options).then(response => {
            let payload = response.data;

            if (load_info) {
                context.dispatch('event/setEventList', payload.data, { root: true });
                context.dispatch('event/setEventCount', payload.count, { root: true });
                context.dispatch('event/setEventLoading', false, { root: true });
            }
            else {
                context.dispatch('event/eventListAppend', payload.data, { root: true });
            }

        });
    },

    async fetchAgeList(context) {
        await PeopleAttrAPI.ageGetAll().then(response => {
            if (response.status !== 200)
                return;

            context.commit('SET_AGE_LIST', response.data);
        });
    },

    async fetchGenderList(context) {
        await PeopleAttrAPI.genderGetAll().then(response => {
            if (response.status !== 200)
                return;

            context.commit('SET_GENDER_LIST', response.data);
        });
    },

    async fetchCategoryList(context) {
        await PeopleAttrAPI.categoryGetAll().then(response => {
            if (response.status !== 200)
                return;

            context.commit('SET_CATEGORY_LIST', response.data);
        });
    },

    async fetchDepartmentList(context) {
        await PeopleAttrAPI.departmentGetAll().then(response => {
            if (response.status !== 200)
                return;

            context.commit('SET_DEPARTMENT_LIST', response.data);
        });
    },

    categorySelectedAppend(context, value) {
        context.commit('CATEGORY_SELECTED_APPEND', value);
    },

    categorySelectedRemove(context, value) {
        context.commit('CATEGORY_SELECTED_REMOVE', value);
    },

    setPeopleCount(context, value) {
        context.commit('SET_PEOPLE_COUNT', value);
    },

    decreasePeopleCount(context, value) {
        context.commit('DECREASE_PEOPLE_COUNT', value);
    },

    setPeopleChunkOffset(context, value) {
        context.commit('SET_PEOPLE_CHUNK_OFFSET', value);
    },

    setPeopleChunkLimit(context, value) {
        context.commit('SET_PEOPLE_CHUNK_LIMIT', value);
    },

    async eventSetGlobalState(context, id_state) {
        let options = await context.dispatch('base/baseOptions', null, { root: true });

        options = {
            ...options,
            id_state: id_state
        }

        const response = await PeopleAPI.eventSetGlobalState(
            context.getters.peopleSelectedId,
            options
        );

        if (response.status === 403) {
            await context.dispatch("core/addNotificationError", {
                    header: "Закрытое событие",
                    message:
                        "Вы не можете обновлять закрытые события.",
                    timeout: 3000,
                }, { root: true });
            return;
        }

        if (response.status !== 200) {
            await context.dispatch("core/addNotificationError", {
                header: "Ошибка обновления события",
                message:
                    "Произошла ошибка с обновлением события...",
                timeout: 2500,
            }, { root: true });
            return;
        }

        // Сбрасываем текущее событие, чтобы не было проблем с родительским
        await context.dispatch("event/clearEventParentId", null, { root: true});
        await context.dispatch('event/setEventSelected', 0, { root: true});
        await context.dispatch('fetchEventList', { load_info: true });
    },

    async companionSet(context) {
        const ids_for_companion = context.getters.peopleLinksIds.slice(0);
        ids_for_companion.push(context.getters.peopleSelectedId);

        const persons_for_update = context.getters.peopleList.filter(t => ids_for_companion.includes(t.id));

        for (let person of persons_for_update) {
            let options = await context.dispatch('base/baseOptionsDate', null, { root: true});

            options = {
                ...options,
                value: !person.companion
            }

            const response = await PeopleAPI.companionSet(person.id, options);

            if (response.status === 403) {
                continue;
            }

            if (response.status !== 200) {
                continue;
            }

            context.commit('PEOPLE_UPDATE', {
                id_people: response.data.id,
                data: response.data
            });
        }
    },

    cardViewShow(context, value) {
        context.commit("CARD_VIEW_SHOW", value);
    },

    cardEmployeeViewShow(context, value) {
        context.commit("CARD_EMPLOYEE_VIEW_SHOW", value);
    },

    async separationEvents(context) {
        // const event_list = context.rootGetters['event/eventList'];
        // if (event_list.length <= 1) {
        //     await context.dispatch("core/addNotificationError", {
        //         header: "Невозможно разделить события персонажа",
        //         message:
        //             "Персонаж должен иметь минимум 2 события, чтобы иметь возможность разделить их.",
        //         timeout: 2500,
        //     }, { root: true });
        //     return;
        // }

        let id_list = [];

        id_list.push(context.rootGetters['event/eventSelectedId']);
        id_list = [...id_list, ...context.rootGetters['event/eventSubIds']]


        const data = {
            id_event: id_list
        }

        const response = await PeopleAPI.separateEvents(data);

        if (response.status !== 200) {
            await context.dispatch("core/addNotificationError", {
                header: "Ошибка разделения событий",
                message:
                    "Произошла ошибка с разделением событий...",
                timeout: 2500,
            }, { root: true });
            return;
        }

        // Обновляем список персонажей
        if (response.status === 200) {
            await context.dispatch('event/eventSubClear', null, { root: true});

            // Временно изменяем текущие значения offset/limit чтобы мы могли
            // выгрузить все текущие данные которые у нас есть.
            const old_offset = context.getters.peopleChunkOffset;
            const old_limit = context.getters.peopleChunkLimit;

            await context.dispatch("setPeopleChunkOffset", 0);
            await context.dispatch("setPeopleChunkLimit", context.getters.peopleList.length);

            const [data, count] = await context.dispatch('returnPeopleList');

            // Возвращаем старые значения для offset/limit
            await context.dispatch("setPeopleChunkOffset", old_offset);
            await context.dispatch("setPeopleChunkLimit", old_limit);

            // Работаем с данными
            await context.dispatch("setPeopleCount", count);

            // Убираем персонажей и добавляем новых
            const old_ids = context.getters.peopleList.map(t => t.id);
            const new_ids = data.map(t => t.id);

            const ids_for_delete = old_ids.filter(t => !new_ids.includes(t));
            const ids_for_append = new_ids.filter(t => !old_ids.includes(t));

            const items_for_append = data.filter(t => ids_for_append.includes(t.id));

            context.commit("PEOPLE_LIST_DELETE_ITEMS", ids_for_delete);
            context.commit("PEOPLE_LIST_APPEND", items_for_append);

            await context.dispatch('event/setEventChunkOffset', 0, { root: true});
            await context.dispatch('fetchEventList', { load_info: true });
        }
    },

    setPeopleLoading(context, value) {
        context.commit("SET_PEOPLE_LOADING", value);
    }
};

const mutations = {
    SET_PEOPLE_LOADING(state, value) {
        state.is_people_loading = value;
    },

    SET_PEOPLE_LIST(state, payload) {
        state.people_list = [...payload];

        if (state.people_list.length > 0) {
            state.people_selected_id = state.people_list[0].id;
        } else {
            state.people_selected_id = 0;
        }
    },

    PEOPLE_LIST_APPEND(state, payload) {
        state.people_list = [
            ...state.people_list,
            ...payload
        ]

        // @Hack: Сортировка должна быть в другом месте
        state.people_list.sort((a, b) => a.begin_time - b.begin_time);

    },

    PEOPLE_LIST_DELETE_ITEMS(state, id_list) {
        state.people_list = state.people_list.filter(t => !id_list.includes(t.id));
    },

    CLEAR_PEOPLE_LIST(state) {
        state.people_list = [];
    },

    SET_PEOPLE_SELECTED(state, value) {
        state.people_selected_id = value;
    },

    SET_AGE_LIST(state, payload) {
        state.age_list = [...payload];
    },

    SET_GENDER_LIST(state, payload) {
        state.gender_list = [...payload];
    },

    SET_CATEGORY_LIST(state, payload) {
        state.category_list = [...payload];
    },

    CATEGORY_SELECTED_APPEND(state, value) {
        state.category_selected_list.push(value);
    },

    CATEGORY_SELECTED_REMOVE(state, value) {
        state.category_selected_list.splice(state.category_selected_list.indexOf(value), 1);
    },

    CATEGORY_SELECTED_CLEAR(state) {
        state.category_selected_list = [];
    },

    SET_DEPARTMENT_LIST(state, payload) {
        state.department_list = [...payload];
    },

    SET_PEOPLE_COUNT(state, value) {
        state.people_count = value;
    },

    DECREASE_PEOPLE_COUNT(state, value) {
        state.people_count = state.people_count - value;
        if (state.people_count < 0)
            state.people_count = 0;
    },

    SET_PEOPLE_CHUNK_OFFSET(state, value) {
        state.people_chunk_offset = value;
    },

    SET_PEOPLE_CHUNK_LIMIT(state, value) {
        state.people_chunk_limit = value;
    },

    PEOPLE_LINKS_IDS_APPEND(state, value) {
        state.people_links_ids.push(value);
    },

    PEOPLE_LINKS_IDS_REMOVE(state, value) {
        state.people_links_ids.splice(state.people_links_ids.indexOf(value), 1);
    },

    PEOPLE_LINKS_IDS_CLEAR(state) {
        state.people_links_ids = [];
    },

    PEOPLE_UPDATE(state, { id_people, data }) {
        let item = state.people_list.find(t => t.id === id_people);

        if (!item) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'id') return;

            if (key === 'begin_time' && value === null) return;
            if (key === 'end_time' && value === null) return;

            if (item[key] !== value) {
                item[key] = value;
            }
        });
    },

    CARD_VIEW_SHOW(state, value) {
        state.card_view.show = value;
    },

    CARD_EMPLOYEE_VIEW_SHOW(state, value) {
        state.card_employee_view.show = value;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
