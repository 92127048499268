<template>
    <v-dialog
        v-model="show"
        max-width="500"
        :style="{ zIndex: 999 }"
        @keydown.esc="onCancel"
        @click:outside="onCancel"
    >
        <div class="flex flex-col px-3 py-3 modal-content">
            <div class="flex flex-row items-center justify-between border-bottom-1 border-solid-dark-gray pb-2">
                <div class="flex flex-row text-sm font-bold">
                    {{ title }}
                </div>

                <div
                    class="flex flex-row rounded items-center cursor-pointer close-button px-1.5 py-0.5"
                    @click="onCancel"
                >
                    <v-icon size="16" color="rgb(255,255,255,0.8)">
                        fas fa-times
                    </v-icon>
                </div>
            </div>

            <div class="flex flex-row w-full mt-2">
                {{ message }}
            </div>

            <div class="flex flex-row justify-end items-center mt-4 text-sm">
                <button
                    class="btn-success py-1 px-2 rounded trans w-20"
                    @click="onConfirm"
                >
                    Да
                </button>
                <button
                    class="btn-danger py-1 px-2 ml-1 rounded trans w-20"
                    @click="onCancel"
                >
                    Нет
                </button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ConfirmDialog",

    computed: {
        ...mapGetters("core", ["confirmDialog"]),

        show: {
            get() {
                return this.confirmDialog.show;
            },

            set(value) {
                if (value) return;

                this.$store.dispatch("core/closeConfirmDialog");
            },
        },

        title() {
            return this.confirmDialog.title;
        },

        message() {
            return this.confirmDialog.message;
        },
    },

    methods: {
        onConfirm() {
            if (this.confirmDialog.onConfirm !== null) {
                if (this.confirmDialog.onConfirm instanceof Function) {
                    this.confirmDialog.onConfirm();
                }
            }

            this.show = false;
        },

        onCancel() {
            if (this.confirmDialog.onCancel !== null) {
                if (this.confirmDialog.onCancel instanceof Function) {
                    this.confirmDialog.onCancel();
                }
            }

            this.show = false;
        },
    },
};
</script>

<style scoped>
.modal-content {
    background-color: var(--main-color);
}

.close-button:hover {
    background-color: rgba(255, 255, 255, 0.15);
}
</style>
