<template>
    <div
        class="flex flex-row items-center justify-center font-bold text-xs bg-purple-800"
        v-show="!is_chrome"
    >
        <div>Для корректной работы приложения, используйте браузер Google Chrome!</div>
    </div>
</template>

<script>
export default {
    name: "BrowserSupport",

    data: () => ({
        is_chrome: false
    }),

    created() {
        this.is_chrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) || (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor));
    },
};
</script>

<style>
</style>
