<template>
    <div
        class="flex flex-row px-1 py-2 notification rounded"
        :class="isShown ? '' : 'notification-hide'"
    >
        <div
            class="flex flex-row ml-1 mr-1 notification-border"
            :class="borderClass"
        ></div>
        <div class="flex flex-row justify-between w-full">
            <!-- <div class="flex mr-2">
                <v-icon color="#2563EB" size="20"> fas fa-info-circle </v-icon>
            </div> -->

            <div class="flex flex-col mx-2">
                <!-- Header -->

                <div class="flex flex-row font-bold text-base">
                    {{ data.header }}
                </div>

                <!-- Body -->
                <div class="flex flex-row notification-body text-sm">
                    <div class="flex flex-col">
                        <div v-for="line in body_lines" :key="line.id">
                            {{ line.message }}
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col p-2 justify-center items-center cursor-pointer notification-times"
                @click="closeNotification"
            >
                &times;
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationItem",

    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },

    data: () => ({
        isShown: true,
        shownTimeout: null,

        body_lines: [],
    }),

    created() {
        if (this.data.timeout > 0) this.setTimeout();

        let body_parts = this.data.message.split("\n");
        body_parts.forEach((value, index) => {
            this.body_lines.push({
                id: this.data.id + index,
                message: value.trim()
            });
        });
    },

    computed: {
        borderClass() {
            return {
                "notification-error": this.data.type === "error",
                "notification-info": this.data.type === "info",
            };
        },
    },

    methods: {
        setTimeout() {
            clearTimeout(this.shownTimeout);
            this.isShown = true;
            this.shownTimeout = setTimeout(() => {
                this.isShown = false;
            }, this.data.timeout);
        },

        closeNotification() {
            this.isShown = false;
        }
    },
};
</script>

<style scoped>
.notification {
    background-color: #3a3b3c;
    min-width: 400px;
    max-width: 400px;
    z-index: 9999;
}

.notification-hide {
    display: none;
}

.notification-times:hover {
    color: rgba(255, 255, 255, 0.5);
}

.notification-body {
    color: #eeeeee;
}

.notification-error {
    background-color: #dc2626;
}

.notification-info {
    background-color: #2563eb;
}

.notification-border {
    width: 4px;
    min-width: 4px;
}
</style>
