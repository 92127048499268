import API, {APIReturn} from '@/api';
import {IEventParams, IPeriod, IPeriodParams as BasePeriodParams, IEvent, IGlobalState} from "@/api/event.api";

const RESOURCE = "/kko";

interface IKkoParams extends IEventParams {
    show_all: boolean,
    search: string
}

interface IKkoItem {
    id: number
    shop: string,
    cash: string,
    begin_time: number,
    end_time: number,
    purchase: string,
    cashier: string,
    count_pos: number,
    sum: number // float
}

interface IKkoItems {
    closed: boolean,
    count: 0,
    data: IKkoItem[]
}

interface IPeriodParams extends BasePeriodParams {
    show_all: boolean
}

interface IEventKKO extends IEvent {
    purchase: string
}

interface IEventKKOList {
    count: number,
    data: IEventKKO[]
}

interface IKkoInfo {
    action_type: string,
    item: string,
    price: number,
    count: number,
    sum: number,
    discount: number,
    sum_after_discount: number,
    time: number,
}

export const KkoAPI = {
    kkoGetAll: async (options: IKkoParams): APIReturn<IKkoItems> => {
        // [GET] /v1/kko/
        return await API.get<IKkoItems>(`${RESOURCE}/`, { params: options });
    },

    periodGet: async (options: IPeriodParams): APIReturn<IPeriod> => {
        // [GET] /v1/kko/period/
        return await API.get<IPeriod>(`${RESOURCE}/period/`, { params: options });
    },

    infoGetAll: async (id_item: number): APIReturn<Array<IKkoInfo>> => {
        // [GET] /v1/kko/:id_item/info/
        return await API.get(`${RESOURCE}/${id_item}/info/`);
    },

    eventGetAll: async (id_item: number, options: IEventParams): APIReturn<IEventKKOList> => {
        // [GET] /v1/kko/:id_item/events/
        return await API.get<IEventKKOList>(`${RESOURCE}/${id_item}/events/`, { params: options });
    },

    eventSetGlobalState: async (id_item: number, options: IGlobalState): APIReturn<string> => {
        return await API.put<string>(`${RESOURCE}/${id_item}/events/set_state/`, { params: options });
    }
};
