<template>
  <v-dialog
    v-model="show"
    max-width="800"
    :style="{ zIndex: 999 }"
    @keydown.esc="closeModal"
    @click:outside="closeModal"
  >
    <div class="flex flex-col p-2 modal-content">
      <!-- Header -->
      <div
        class="
          pb-2
          flex flex-row
          items-center
          justify-between
          border-bottom-1 border-solid-dark-gray
        "
      >
        <div class="flex flex-row text-xs font-bold">
          {{ title }}
          <div class="ml-2 font-bold text-second" v-show="!isNewEvent">
            # {{ event_local.id }}
          </div>
        </div>

        <div
          class="
            flex flex-row
            rounded
            items-center
            cursor-pointer
            close-button
            px-1.5
            py-0.5
          "
          @click="closeModal"
        >
          <v-icon size="16" color="rgb(255,255,255,0.8)"> fas fa-times </v-icon>
        </div>
      </div>

      <!-- Pages -->

      <div class="flex flex-row items-center">
        <div
          v-for="page in pages"
          :key="page.id"
          class="
            flex flex-row
            text-xs
            font-bold
            px-2
            py-1
            cursor-pointer
            page-item
          "
          :class="{ 'page-item-active': page.id === page_selected }"
          @click="selectPage(page.id)"
          v-show="page.show"
        >
          {{ page.name }}
        </div>
      </div>

      <!-- Content -->
      <div class="flex flex-col mt-2 px-1 modal-body">
        <!-- Page 1 -->

        <div v-show="page_selected === 1">
          <div class="flex flex-row items-center text-sm mb-2">
            <div class="flex flex-row items-center fb-50">
              <div class="w-32">{{getTranslate('start_time')}}:</div>
              <input
                type="text"
                class="px-2 flex-grow mr-4"
                :disabled="event_local.closed"
                v-model="local_begin_time"
              />
            </div>

            <div class="flex flex-row items-center fb-50">
              <div class="w-28">{{getTranslate('end_time')}}:</div>

              <input
                type="text"
                class="px-2 flex-grow"
                :disabled="event_local.closed"
                v-model="local_end_time"
              />
            </div>
          </div>

          <div class="flex flex-row items-center text-sm mb-2">
            <div class="w-32">{{getTranslate('comment')}}:</div>
            <input
              type="text"
              class="px-2 flex-grow"
              :disabled="event_local.closed"
              v-model="event_local.comment"
            />
          </div>

          <div class="flex flex-row items-center text-sm mb-2">
            <div class="w-32">{{getTranslate('event_type')}}:</div>
            <select
              class="flex-grow"
              v-model="event_local.id_event_type"
              :disabled="event_local.closed"
            >
              <option
                v-for="item in eventTypeList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="flex flex-row items-center text-sm mb-2">
            <div class="w-32">{{getTranslate('camera')}}:</div>
            <select
              class="flex-grow"
              v-model="event_local.id_camera"
              :disabled="event_local.closed"
            >
              <option
                v-for="item in cameraList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="flex flex-row items-center text-sm mb-2">
            <div class="w-32">{{getTranslate('zone')}}:</div>
            <select
              class="flex-grow"
              v-model="event_local.id_camera_zone"
              :disabled="event_local.closed"
            >
              <option
                v-for="item in cameraZoneList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Page 2 -->

        <div v-show="page_selected === 2">
          <div class="flex flex-row items-center text-sm mb-2">
            <div class="w-32">{{getTranslate('car_plate')}}:</div>

            <v-combobox
              v-model="plate_model"
              :items="plate_data_items"
              :search-input.sync="plate_search"
              dark
              item-color="primary"
              background-color="#3a3b3c"
              height="25px"
              hide-details
              class="text-sm"
            >
            </v-combobox>
          </div>

          <div class="flex flex-row items-center text-sm mb-2">
            <div class="w-32">{{getTranslate('work_orders')}}:</div>
            <input
              type="text"
              class="px-2 flex-grow"
              v-model="pluginAutoOrders"
              :disabled="event_local.closed"
            />
          </div>
        </div>
      </div>

      <!-- Footer -->

      <div
        class="flex flex-row w-full mt-2 items-center justify-end"
        v-show="!event_local.closed"
      >
        <div
          class="rounded px-2 py-1 text-sm cursor-pointer"
          :class="{
            'btn-danger-outline': !event_local.deleted,
            'btn-danger': event_local.deleted,
          }"
          @click="onDeleteEvent"
          v-show="!isNewEvent && !event_local.deleted"
        >
          {{ event_local.deleted ? getTranslate('restore') : getTranslate('delete') }}
        </div>

        <div
          class="
            btn-success-outline
            rounded
            px-2
            py-1
            text-sm
            ml-2
            cursor-pointer
          "
          @click="saveEvent"
          v-show="!event_local.deleted"
        >
          {{getTranslate('save')}}
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { AutoAPI } from "@/api/auto.api.ts";
import { EventAPI } from "@/api/event.api.ts";

import { formatDateTime, toSeconds } from "@/utils/time.js";

export default {
  name: "EventModal",

  data: () => ({
    event_local: {},

    // @Temporary: Временное решение чтобы переводить
    // числа который приходят нам в события в человеческое время.
    // Когда будет нормальный компонент для выбора времени, нужно
    // будет убрать
    //  -n.kushnarenko 02.06.2021
    local_begin_time: "",
    local_end_time: "",

    plate_model: null,
    plate_data_items: [],
    plate_data_timeout: null,
    plate_search: null,

    pages: [
      {
        id: 1,
        name: "Событие",
        show: true,
      },
      {
        id: 2,
        name: "Автомобиль",
        show: false,
      },
    ],
    page_selected: 1,
  }),

  computed: {
    ...mapGetters("ski", ["dataSelectedId"]),

    ...mapGetters("base", ["cameraList", "cameraZoneList", "getTranslate"]),

    ...mapGetters("event", ["eventModal", "eventAllList", "eventTypeList"]),

    show: {
      get() {
        return this.eventModal.show;
      },

      set() {
        this.$store.dispatch("event/closeEventModal");
        this.event_local = {};
      },
    },

    isNewEvent() {
      return this.event_local.id === 0;
    },

    title() {
      return this.event_local.id === 0
        ? this.getTranslate('new_event')
        : this.getTranslate('edit_event');
    },

    pluginAutoOrders: {
      get() {
        if (this.event_local.auto === undefined) return "";

        return this.event_local.auto.id_orders;
      },

      set(value) {
        this.event_local.auto.id_orders = value.split(",").map((val) => {
          if (!val) return;
          const parsed = parseInt(val);
          if (isNaN(parsed)) return;
          return parsed;
        });
      },
    },
  },

  watch: {
    async show(value) {
      if (!value) return;

      await this.initEventModal();
    },

    async plate_search(value) {
      if (value.length > 40) {
        return;
      }

      this.plate_model = value;
      if (!value || value.length <= 1) {
        this.plate_data_items = [];
        return;
      }

      if (this.plate_data_timeout) {
        clearTimeout(this.plate_data_timeout);
      }

      this.plate_data_timeout = setTimeout(async () => {
        await this.fetchPlateDataList(value);
      }, 150);
    },
  },

  methods: {
    async fetchPlateDataList(value) {
      let options = {
        plate: value,
      };

      await AutoAPI.plateSearchOrder(options).then((response) => {
        this.plate_data_items = response.data;
      });
    },

    async initEventModal() {
        this.pages= [
          {
            id: 1,
            name: this.getTranslate("event"),
            show: true,
          },
          {
            id: 2,
            name: this.getTranslate('auto'),
            show: false,
          },
        ];
      this.page_selected = 1;
      this.pages.filter((t) => t.id !== 1).forEach((a) => (a.show = false));

      // Если 0 то надо создать событие
      if (this.eventModal.id_event === 0) {
        this.event_local = {
          id: 0,
          begin_time: "",
          end_time: "",
          id_camera: 0,
          id_camera_zone: 0,
          id_event_type: 0,
          comment: "",
        };

        this.local_begin_time = "";
        this.local_end_time = "";
      } else {
        const local = this.eventAllList.find(
          (t) => t.id === this.eventModal.id_event
        );

        this.event_local = JSON.parse(JSON.stringify(local));

        // Handle pages
        this.pages.find((t) => t.id === 2).show =
          this.event_local.auto !== undefined;

        if (this.event_local.auto !== undefined) {
          this.plate_model = this.event_local.auto.plate;
        }

        this.local_begin_time = formatDateTime(
          this.event_local.begin_time,
          "yyyy-LL-dd'T'HH:mm:ss"
        );
        this.local_end_time = formatDateTime(
          this.event_local.end_time,
          "yyyy-LL-dd'T'HH:mm:ss"
        );
      }
    },

    closeModal() {
      this.show = false;
    },

    onDeleteEvent() {
      this.$store.dispatch("core/showConfirmDialog", {
        title: "Удалить событие?",
        message:
          "Вы действительно хотите удалить событие? Его нельзя будет восстановить!",
        onConfirm: this.onDialogConfirm,
        onCancel: null,
      });
    },

    async onDialogConfirm() {
      await EventAPI.eventDelete(this.event_local.id).then((response) => {
        if (response.status === 403) {
          this.$store.dispatch("core/addNotificationError", {
            header: "Закрытое событие",
            message: "Вы не можете удалять закрытое события.",
            timeout: 3000,
          });
          return;
        }

        this.event_local.deleted = !this.event_local.deleted;

        this.$store.dispatch("event/eventUpdateApply", {
          id_event: this.event_local.id,
          data: this.event_local,
        });
      });
    },
    ...mapActions("ski", ["fetchEventList"]),
    async saveEvent() {
      // TODO: validation
      const bt = toSeconds(this.local_begin_time);
      const et = toSeconds(this.local_end_time);

      if (isNaN(bt) || isNaN(et)) {
        await this.$store.dispatch("core/addNotificationInfo", {
          header: "Неправильный формат времени.",
          message:
            "Редактирование времени в данный момент в разработке.\nВведите формат времени в формате требующим системой.\nПример: 2021-01-20T12:34",
          timeout: 10000,
        });
        return;
      }

      let data = {
        begin_time: bt,
        end_time: et,
        id_camera: this.event_local.id_camera,
        id_camera_zone: this.event_local.id_camera_zone,
        id_event_type: this.event_local.id_event_type,
        comment: this.event_local.comment,
        id_state: this.event_local.id_state,
      };

      if (this.event_local.auto !== undefined) {
        data = {
          ...data,
          auto: {
            plate: this.plate_model,
            id_order: this.event_local.auto.id_orders.filter(
              (t) => t !== undefined
            ),
          },
        };
      }

      if (this.event_local.id === 0) {
        await EventAPI.eventCreate(data).then((response) => {
          if (response.status !== 200) {
            this.$store.dispatch("core/addNotificationError", {
              header: "Не удалось создать событие",
              message:
                "По какой-то причине не удалось создать событие.\nОбратитесь к администратору!",
              timeout: 5000,
            });
          }

          this.eventAllList.push(response.data);

          console.log("Create event");
        });
      } else {
        if (this.event_local.auto !== undefined) {
          await this.$store.dispatch("auto/eventUpdate", {
            id_event: this.event_local.id,
            data: data,
          });
        } else if (this.event_local.attrs != null && this.event_local.attrs["md_skipass.etalon"] !== undefined) {
          await this.$store.dispatch("ski/skiEventUpdate", {
            // skipass id
            id: this.dataSelectedId,
            id_event: this.event_local.id,
            data: data,
          });
        } else {
          await this.$store.dispatch("event/eventUpdate", {
            id_event: this.event_local.id,
            data: data,
          });
        }
      }
      this.show = false;
    },

    selectPage(id_page) {
      if (this.page_selected === id_page) return;
      this.page_selected = id_page;
    },
  },
};
</script>

<style scoped>
.modal-content {
  background-color: var(--main-color);
  width: 100%;
  height: 100%;
}

.modal-body {
  min-height: 165px;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

input[type="text"] {
  background-color: #3a3b3c;
  border: 1px solid var(--dark-gray);
  height: 25px;
}

select {
  padding: 0.25rem;
  background-color: #3a3b3c;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;

  border: 1px solid var(--dark-gray);
  height: 25px;
}

.page-item {
  border: 1px solid var(--dark-gray);
}

.page-item-active {
  border-top: 2px solid #f59e0b;
  border-bottom: none;
  margin-top: -2px;
}
</style>
